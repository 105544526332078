import React from 'react'
import { Navigate } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import MainLayout from 'src/layouts/MainLayout'
import DashboardView from 'src/views/reports/DashboardView'
import LoginView from 'src/views/auth/LoginView'
import DiscoveryView from 'src/views/discovery/DiscoveryView'
import RegisterView from 'src/views/auth/RegisterView'
import SettingsView from 'src/views/settings/SettingsView'
import SneakersView from 'src/views/sneakers/SneakersView'
import NewsView from 'src/views/news/NewsView'
import EventsView from 'src/views/events/EventsView'
import ShowsView from 'src/views/shows/ShowsView'
import ShowView from 'src/views/show/ShowView'
import HomeView from 'src/views/home/HomeView'

import BadRequestView from 'src/views/errors/BadRequestView'
import NotAuthrizedView from 'src/views/errors/NotAuthorizedView'
import NotAuthenticatedView from 'src/views/errors/NotAuthenticatedView'
import NotFoundView from 'src/views/errors/NotFoundView'
import InternalServerErrorView from 'src/views/errors/InternalServerErrorView'
export const loggedInRoutes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'home', element: <HomeView /> },
      { path: 'discovery', element: <DiscoveryView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'sneakers', element: <SneakersView /> },
      { path: 'events', element: <EventsView /> },
      { path: 'news', element: <NewsView /> },
      { path: 'shows', element: <ShowsView /> },
      { path: 'shows/:id', element: <ShowView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '400', element: <BadRequestView /> },
      { path: '401', element: <NotAuthenticatedView /> },
      { path: '403', element: <NotAuthrizedView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '500', element: <InternalServerErrorView /> },
      { path: '/', element: <Navigate to="/app/home" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
]

export const notLoggedInRoutes = [
  { path: 'login', element: <LoginView /> },
  { path: '/', element: <Navigate to="/login" /> },
  { path: '*', element: <Navigate to="/login" /> }
]
