import React from 'react'
import { Container } from '@material-ui/core'
import { ReactComponent as Icon } from './svg/success.svg'
const SuccessIcon = () => {
  return (
    <Container>
      <Icon style={{ color: 'green' }} />
    </Container>
  )
}

export default SuccessIcon
