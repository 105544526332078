import React, { useState, useEffect, useRef } from 'react'
import { styled, withTheme } from '@material-ui/core/styles'

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Container,
  makeStyles
} from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'

import AddModalContent from 'src/components/modal/AddModalContent'
import ConfirmModalContent from 'src/components/modal/ConfirmModalContent'
import CustomButton from 'src/components/buttons/CustomButton'
import Modal from '../../../components/modal/modal'

import { endpoints } from '../../../utils/API_endpoints'
import { SETTINGS } from '../../../utils/pageSettings'
import { formatDate } from '../../../utils/functions'
import theme from '../../../theme/theme'
import useHttp from '../../../hooks/http'
import { CommentModalContent } from 'src/components/modal/CommentModalContent'

const Circle = styled(withTheme(Card))((props) => ({
  width: 12,
  height: 12,
  borderRadius: 12 / 2,
  border: '0.3px black',
  backgroundColor: props.active
    ? props.theme.palette.text.secondary
    : props.theme.palette.text.white,
  boxShadow: '6px 4px 35px -17px rgba(0,0,0,0.75)',
  '&:not(:first-child)': {
    marginLeft: props.theme.spacing(1)
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    maxWidth: '100%'
  },
  media: {
    maxHeight: '70%'
  },
  pictureSlidesContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  chartContainer: {
    marginTop: theme.spacing(2)
  },
  votesIcon: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(0.5),
    alignContent: 'center',
    '& :last-child': {
      marginTop: theme.spacing(0.4)
    },
    '&  > *': {
      marginRight: theme.spacing(1)
    }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row'
  },
  dateAndLocationContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start'
    }
  },
  date: {
    width: 'auto',
    margin: '0',
    marginBottom: theme.spacing(1)
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    margin: '0',
    alignItems: 'flex-start',
    '& :first-child': {
      paddingBottom: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end'
    }
  },

  arrowBackward: {
    top: '50%',
    left: '0',
    position: 'absolute'
  },
  arrowForward: {
    top: '50%',
    right: '0',
    position: 'absolute'
  },
  circleCentering: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  circleContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    bottom: '10px',
    width: '100%'
  }
}))
export default function EventCard({
  putEvent,
  deleteEvent,
  toggleComments,
  publishItem,
  parentHook,
  parentData,
  clearState,
  alert
}) {
  const classes = useStyles()
  const [APIdata, setAPIdata] = useState(null)
  const [, setUpVote] = useState(0)
  const [, setDownVote] = useState(0)
  const [responseAlertData, setResponseAlertData] = alert
  const [tagsData, setTagsData] = useState(null)
  const [tags, setTags] = useState({})
  const [displayPictures, setDisplayPictures] = useState(parentData.image)
  const [pictureIndex, setPictureIndex] = useState(0)

  const {
    isLoading,
    error,
    data,
    statusCode,
    sendRequest,
    reqExtra,
    clear
  } = useHttp()

  const setResponseAlertDataRef = useRef(() => {})
  const getEventsRef = useRef(() => {})
  const getEventTagfsRef = useRef(() => {})

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  getEventsRef.current = () => {
    sendRequest(
      `${endpoints.events}/${parentData._id}/votes`,
      'GET',
      null,
      'get'
    )
  }

  getEventTagfsRef.current = () => {
    sendRequest(`${endpoints.tags}/${parentData._id}`, 'GET', null, 'tags')
  }

  useEffect(() => {
    if (!isLoading && !error && data && reqExtra === 'get') {
      clearVotes()
      setAPIdata(data)
    }
    if (!isLoading && !error && data && reqExtra === 'tags') {
      setTagsData(data[0])
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getEventsRef.current()

    return () => clear()
  }, [clear])

  useEffect(() => {
    getEventTagfsRef.current()

    return () => clear()
  }, [responseAlertData, clear])

  useEffect(() => {
    setPictureIndex(0)
    setDisplayPictures(parentData.image)
  }, [parentData.image])

  const putTags = () => {
    sendRequest(
      `${endpoints.tags}/${parentData._id}`,
      'PUT',
      JSON.stringify(tags),
      'action'
    )
  }

  const clearTagState = () => {
    setTags({})
  }

  const clearVotes = () => {
    setUpVote(0)
    setDownVote(0)
  }

  useEffect(() => {
    if (!APIdata?.error) {
      APIdata?.forEach(({ action }) => {
        if (action) {
          setUpVote((prevVote) => prevVote + 1)
        } else {
          setDownVote((prevVote) => prevVote + 1)
        }
      })
    }
  }, [APIdata])

  const handlePast = () => {
    if (pictureIndex - 1 < 0) {
      setPictureIndex(displayPictures.length - 1)
    } else {
      setPictureIndex((pictureIndex) => pictureIndex - 1)
    }
  }
  const handleNext = () => {
    if (pictureIndex + 1 > displayPictures.length - 1) {
      setPictureIndex(0)
    } else {
      setPictureIndex((pictureIndex) => pictureIndex + 1)
    }
  }

  const renderCircles = () => {
    return displayPictures?.map((_, i) => {
      return <Circle key={i} active={i === pictureIndex ? 1 : 0}></Circle>
    })
  }

  const renderPicture = () => {
    return (
      <CardMedia
        component="img"
        className={classes.media}
        image={displayPictures[pictureIndex]}
        title="Picture on shoes"
      />
    )
  }

  return (
    <>
      <Card className={classes.cardContainer}>
        <Container style={{ padding: 0 }}>
          <div className={classes.pictureSlidesContainer}>
            <ArrowForwardIosRoundedIcon
              onClick={handleNext}
              style={{ color: theme.palette.text.icon, opcity: '.5' }}
              className={classes.arrowForward}
            />
            {renderPicture()}
            <ArrowBackIosRoundedIcon
              onClick={handlePast}
              style={{ color: theme.palette.text.icon }}
              className={classes.arrowBackward}
            />
            <div className={classes.circleContainer}>
              <div className={classes.circleCentering}>{renderCircles()}</div>
            </div>
          </div>
          <CardContent>
            <Typography
              variant="subtitle1"
              component="h3"
              style={{ fontSize: '30px', fontWeight: '200' }}
            >
              {parentData.tourName}
            </Typography>
            <Container
              className={classes.dateAndLocationContainer}
              style={{ padding: '0' }}
            >
              <Container className={classes.date} style={{ padding: '0' }}>
                <Typography
                  variant="subtitle1"
                  component="h3"
                  style={{ fontSize: '13px', fontWeight: '200' }}
                >
                  {formatDate(parentData.date)}
                </Typography>
              </Container>
              <Container className={classes.location} style={{ padding: '0' }}>
                <RoomOutlinedIcon />
                <Typography variant="body2" component="h6">
                  {parentData.location}, {parentData.venue}
                </Typography>
              </Container>
            </Container>
            <Typography
              gutterBottom
              variant="body2"
              component="h6"
              style={{ fontSize: '15px', fontWeight: '200' }}
            >
              Visningar: {parentData.traffic}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              component="h6"
              style={{ fontSize: '15px', fontWeight: '200' }}
            >
              Prenumerationer: {parentData.subscriptions}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              component="h6"
              style={{ fontSize: '15px', fontWeight: '200' }}
            >
              Måste gå: {parentData.mustGo}
            </Typography>
            <Typography
              gutterBottom
              variant="subtitle2"
              component="h2"
              style={{ fontSize: '20px', fontWeight: '400' }}
            >
              {parentData.artist}
            </Typography>
            <Typography
              variant="body2"
              component="h6"
              style={{ whiteSpace: 'pre-line' }}
            >
              {parentData.info}
            </Typography>
            <Typography
              variant="body2"
              component="h6"
              style={{ whiteSpace: 'pre-line' }}
            >
              {parentData.extraInfo}
            </Typography>
          </CardContent>
        </Container>
        <CardActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div className={classes.actionContainer}>
            <div className={classes.actionRow}>
              <Modal
                content={
                  <ConfirmModalContent
                    title={'Är du säker?'}
                    action={() => publishItem(parentData._id)}
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={
                      parentData.isPublished
                        ? theme.palette.primary.main
                        : theme.palette.text.secondary
                    }
                    backgroundColor={
                      parentData.isPublished
                        ? theme.palette.background.paper
                        : null
                    }
                    isDisabled={parentData.isPublished}
                    text={parentData.isPublished ? 'PUBLICERAD' : 'PUBLICERA'}
                  />
                }
              />
              <Modal
                content={
                  <ConfirmModalContent
                    title={'Är du säker?'}
                    action={() => deleteEvent(parentData._id)}
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={theme.palette.text.secondary}
                    text={'Delete'}
                  />
                }
              />
              <Modal
                content={
                  <AddModalContent
                    title={'Redigera nyhet'}
                    parentHook={parentHook}
                    parentData={parentData}
                    inputs={SETTINGS.EVENTS}
                    action={putEvent}
                    clearState={clearState}
                    isPublished={parentData?.isPublished}
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={theme.palette.text.secondary}
                    text={'Edit'}
                  />
                }
              />
              <Modal
                content={
                  <AddModalContent
                    title={'Lägg till sökord'}
                    inputs={SETTINGS.TAGS}
                    parentHook={[tags, setTags]}
                    parentData={tagsData}
                    action={putTags}
                    clearState={clearTagState}
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={theme.palette.text.secondary}
                    text={'Taggar'}
                  />
                }
              />
            </div>
            <div className={classes.actionRow}>
              <Modal
                content={
                  <ConfirmModalContent
                    title={'Är du säker?'}
                    action={() =>
                      toggleComments(
                        parentData._id,
                        parentData.commentsDisabled
                      )
                    }
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={theme.palette.text.secondary}
                    text={
                      parentData.commentsDisabled
                        ? 'GÖR KOMMENTARER PUBLIKA'
                        : 'STÄNG AV KOMMENTARER'
                    }
                  />
                }
              />
              <Modal
                content={
                  <CommentModalContent
                    title={'Kommentarer'}
                    objectId={parentData._id}
                    object
                  />
                }
                button={
                  <CustomButton
                    size={'small'}
                    color={theme.palette.text.secondary}
                    text={'Kommentarer'}
                  />
                }
              />
            </div>
          </div>
        </CardActions>
      </Card>
    </>
  )
}
