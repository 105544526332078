import { SETTINGS } from '../utils/pageSettings'

export const prepareBody = (obj) => {
  for (const property in obj) {
    if (obj[property] === '' || obj[property] === []) {
      delete obj[property]
    }
  }
  delete obj['_id']
  return obj
}

export const formatDate = (date) => {
  let timestring = ''
  const convertedDate = new Date(date)
  timestring = `${convertedDate.getFullYear()}-${(convertedDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${convertedDate.getDate().toString().padStart(2, '0')}`
  if (date.split('T').length > 1) {
    timestring += ` ${convertedDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${convertedDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  }
  return timestring
}

export const checkObject = (type, data, setData) => {
  const settings = SETTINGS[type.toUpperCase()]
  Object.values(settings).forEach((obj) => {
    if (!obj.required && data[obj.content.eng] === undefined) {
      setData((prevState) => ({ ...prevState, [obj.content.eng]: '' }))
    }
  })
}
