import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Comment from './Comment'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#424242',
    color: 'textPrimary',
    borderRadius: '8px'
  },
  inline: {
    display: 'inline',
    color: 'white',
  },
  commentText: {
    color: 'gray'
  },
  time: {
    fontSize: '0.8rem',
    paddingTop: '1px'
  }
}))

export default function CommentsList({ comments, objectId, updateComments }) {
  const classes = useStyles()

  return (
    <List className={classes.root}>
      {comments.length &&
        comments.map((c) => (
          <Comment
            comment={c}
            objectId={objectId}
            updateComments={updateComments}
          />
        ))}
    </List>
        
  )
}
