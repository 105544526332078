import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardMedia
} from '@material-ui/core'

import Page from 'src/components/Page'
import LinnearLoader from '../../../components/LinearLoader'

import useStatusCodeView from '../../../hooks/useStatusCodeView'
import useHttp from '../../../hooks/http'
import { endpoints } from '../../../utils/API_endpoints'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cardContainer: {
    // width: '100%',
    height: '100%',
    position: 'relative',
    borderBottom: '2px solid yellow'
  },
  media: {
    objectFit: 'cover',
    height: '80%',
    maxHeight: '80%',

    overflow: 'hidden'
  },
  infomationContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '90px',
    backgroundColor: theme.palette.background.paper,
    '& > *': {
      fontSize: '1rem',
      paddingTop: theme.spacing(1)
    }
  },
  title: {
    fontSize: '40px'
  },
  orderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.4),
    margin: theme.spacing(1),
    position: 'absolute',
    height: '30px',
    width: '20px',
    backgroundColor: theme.palette.primary.main
  },
  order: {
    fontWeight: '1000'
    // position: 'absolute'
  }
}))

const translateObject = {
  episodes: { title: 'title', image: 'thumbnail' },
  sneakers: { title: 'model', image: 'image' },
  news: { title: 'title', image: 'image' },
  events: { title: 'tourName', image: 'image' }
}

export default function Discovery() {
  const classes = useStyles()
  const [trending, setTrending] = useState(null)
  const [discovery, setDiscovery] = useState(null)
  const {
    isLoading,
    error,
    statusCode,
    data,
    clear,
    sendRequest,
    reqExtra
  } = useHttp()

  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)

  const sendRequestRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})

  sendRequestRef.current = (page) => {
    sendRequest(`${endpoints.home}/${page}`, 'GET', null, page)
  }
  clearRef.current = () => {
    clear()
  }

  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'trending') {
      if (statusCode < 300) {
        setTrending(data[0]?.trendingPages)
      }
    }
    if (
      !isLoading &&
      !error &&
      statusCode &&
      data &&
      reqExtra === 'discovery'
    ) {
      if (statusCode < 300) {
        setDiscovery(data)
      }
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    sendRequestRef.current('trending')
    sendRequestRef.current('discovery')

    return () => clearRef.current()
  }, [])

  const renderTrendingItems = () => {
    return trending?.map((page, i) => {
      return (
        <Grid
          item
          id={page.data._id}
          key={page.data._id}
          xs={6}
          sm={3}
          md={2}
          lg={2}
        >
          <Card key={page.data._id} className={classes.cardContainer}>
            <Container className={classes.orderContainer}>
              <Typography
                className={classes.order}
                variant="h4"
                color="textPrimary"
              >
                {i + 1}
              </Typography>
            </Container>
            <CardMedia
              component="img"
              className={classes.media}
              image={
                Array.isArray(page.data[translateObject[page.type].image])
                  ? page.data[translateObject[page.type].image][0]
                  : page.data[translateObject[page.type].image]
              }
              title="Picture on shoes"
            />
            <Container className={classes.infomationContainer}>
              <Typography variant="h4" color="secondary">
                {page.data[translateObject[page.type].title]}
              </Typography>
              <Typography></Typography>
            </Container>
          </Card>
        </Grid>
      )
    })
  }

  const renderDiscoveryItems = () => {
    return discovery?.map((page, i) => {
      if (!page) return null
      return (
        <Grid
          item
          id={page.data._id}
          key={page.data._id}
          xs={6}
          sm={3}
          md={2}
          lg={2}
        >
          <Card key={page.data._id} className={classes.cardContainer}>
            <CardMedia
              component="img"
              className={classes.media}
              image={
                Array.isArray(page.data[translateObject[page.type].image])
                  ? page.data[translateObject[page.type].image][0]
                  : page.data[translateObject[page.type].image]
              }
              title="Picture on shoes"
            />
            <Container className={classes.infomationContainer}>
              <Typography variant="h4" color="secondary">
                {page.data[translateObject[page.type].title]}
              </Typography>
              <Typography></Typography>
            </Container>
          </Card>
        </Grid>
      )
    })
  }

  return (
    <>
      {isLoading && <LinnearLoader />}
      <Page className={classes.root} title="Shows">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Typography className={classes.title} color={'secondary'}>
              Trending
            </Typography>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              mr={2}
            >
              {trending && renderTrendingItems()}
            </Grid>
          </Box>
          <Box mt={3}>
            <Typography className={classes.title} color={'secondary'}>
              Discovery
            </Typography>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              mr={2}
            >
              {renderDiscoveryItems()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}
