import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  makeStyles,
  Container,
  Box,
  Grid,
  IconButton,
  Card,
  CardMedia,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Button,
  Typography
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import Stepper from './Stepper'
import { endpoints } from 'src/utils/API_endpoints'
import Page from 'src/components/Page'
import LinnearLoader from '../../../components/LinearLoader'

import useStatusCodeView from '../../../hooks/useStatusCodeView'
import useHttp from '../../../hooks/http'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    borderRadius: theme.spacing(6)
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default
  },
  container: {
    position: 'relative',

    height: '100%',
    width: '100%'
  },
  addCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  pictureSlidesContainer: {
    objectFit: 'cover',
    padding: '162.5px',
    overflow: 'hidden'
  },
  iconContainer: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  iconButton: {
    margin: theme.spacing(0.5),

    backgroundColor: theme.palette.background.paper,

    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  icon: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.background.paper
    }
  },
  editContainer: {
    height: theme.spacing(6),
    backgroundColor: 'red',
    padding: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  },
  actionGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
  },
  SettingsHeader: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spacer: {
    height: '48px'
  },
  selectDropdown: {
    color: theme.palette.primary.main
  }
}))

export default function HomeView() {
  const classes = useStyles()
  const [APIData, setAPIData] = useState(null)
  const [text, setText] = useState({})

  const [responseAlertData, setResponseAlertData] = useState()
  const [body, setBody] = useState({})
  const [selectedCardSettings, setSelectedCardSettings] = useState()
  const [checked, setChecked] = useState()
  const [orderObject, setOrderObject] = useState([])
  const {
    isLoading,
    error,
    data,
    sendRequest,
    statusCode,
    clear,
    reqExtra
  } = useHttp()

  let navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)

  const getHomeContentRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const reciveCodeRef = useRef(() => {})

  getHomeContentRef.current = () => {
    sendRequest(`${endpoints.home}`, 'GET', null, 'get')
  }

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  reciveCodeRef.current = (code) => {
    recieveCode(code)
  }

  useEffect(() => {
    if (statusCode) {
      reciveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      if (data.length) {
        data.sort((a, b) => a.order - b.order)
      }

      setAPIData(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, statusCode, reqExtra])

  useEffect(() => {
    getHomeContentRef.current()

    return () => clear()
  }, [responseAlertData, clear])

  const removeCard = (id) => {
    sendRequest(`${endpoints.home}/${id}`, 'DELETE', null, 'action')
  }

  const openSettings = (id) => {
    setChecked(APIData.find(({ _id }) => _id === id).isAd)
    setSelectedCardSettings(id)
  }

  const handleChange = (value, selector) => {
    setText({ ...text, [selector]: value })
    setBody((prevState) => ({ ...prevState, [selector]: value }))
  }

  const handleRadioButtonChange = () => {
    setChecked(!checked)
    setBody((prevState) => ({
      ...prevState,
      isAd: !checked
    }))
  }

  const updateHomeContent = (data, id) => {
    sendRequest(
      `${endpoints.home}/${id}`,
      'PUT',
      JSON.stringify(data),
      'action'
    )
  }

  const findIndexByValue = (key, value) => {
    return APIData.findIndex((object) => object[key] === value)
  }

  const orderChange = (value, current) => {
    const targetIndex = findIndexByValue('order', value)
    setOrderObject({
      ...orderObject,
      id: APIData[targetIndex]._id,
      body: { order: current }
    })
  }

  const handleClick = () => {
    updateHomeContent(body, selectedCardSettings)
    if (orderObject) {
      updateHomeContent(orderObject.body, orderObject.id)
    }
    setSelectedCardSettings(null)
  }

  const renderSettingsCard = (data) => {
    return (
      <Card className={classes.addCardContainer}>
        <Container className={classes.SettingsHeader}>
          <Typography variant="h3">Redigera</Typography>
        </Container>
        <Container className={classes.textField}>
          {data.image && (
            <TextField
              required
              fullWidth
              id="standard-required-1"
              label="Bild"
              value={text['image'] || data.image}
              onChange={(e) => handleChange(e.target.value, 'image')}
            />
          )}
          {data.link && (
            <TextField
              required
              fullWidth
              id="standard-required-1"
              label="Länk"
              value={text['link'] || data.link}
              onChange={(e) => handleChange(e.target.value, 'link')}
            />
          )}

          <TextField
            id="demo-simple-select"
            label="Byt plats i ordningen"
            required
            select
            fullWidth
            onChange={(e) => {
              orderChange(e.target.value, data.order)
              handleChange(e.target.value, 'order')
            }}
            SelectProps={{
              className: classes.select,
              MenuProps: {
                classes: {
                  paper: classes.selectDropdown
                }
              }
            }}
          >
            {APIData &&
              APIData.map((x) => {
                return (
                  <MenuItem key={x._id} value={x.order}>
                    {x.order + 1}
                  </MenuItem>
                )
              })}
          </TextField>

          <FormControlLabel
            control={<Checkbox />}
            checked={checked}
            value={checked}
            onChange={handleRadioButtonChange}
            label="Annons"
          />
          {!data.link && <Container className={classes.spacer}></Container>}
        </Container>
        <Container className={classes.actionGroup}>
          <Button
            onClick={() => setSelectedCardSettings(null)}
            className={classes.button}
          >
            Avbryt
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.button}
          >
            Spara
          </Button>
        </Container>
      </Card>
    )
  }
  const renderHomeCard = (data) => (
    <Container className={classes.container} style={{ padding: 0 }}>
      <Container className={classes.iconContainer}>
        <IconButton
          className={classes.iconButton}
          onClick={() => openSettings(data._id)}
        >
          <SettingsIcon className={classes.icon} />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={() => removeCard(data._id)}
        >
          <CloseRoundedIcon className={classes.icon} />
        </IconButton>
      </Container>
      <CardMedia
        className={classes.pictureSlidesContainer}
        image={data.image}
        title="Bild på objekt"
      />
    </Container>
  )

  const newItem = () => {
    return APIData?.map((data) => (
      <Grid
        item
        id={data._id}
        key={data._id}
        xs={12}
        sm={6}
        md={6}
        lg={true}
        xl={3}
      >
        <Card className={classes.cardC}>
          {selectedCardSettings === data._id
            ? renderSettingsCard(data)
            : renderHomeCard(data)}
        </Card>
      </Grid>
    ))
  }

  const card = () => {
    return (
      <Grid
        item
        id={'extra'}
        key={'extra'}
        xs={12}
        sm={6}
        md={6}
        lg={true}
        xl={3}
      >
        <Card className={classes.addCardContainer}>
          <Container style={{ padding: 0, height: '100%' }}>
            <Stepper
              length={APIData?.length}
              parentHook={[body, setBody]}
              responseAlertData={responseAlertData}
              setResponseAlertData={setResponseAlertData}
            />
          </Container>
        </Card>
      </Grid>
    )
  }

  return (
    <>
      {isLoading && <LinnearLoader />}
      <Page className={classes.root} title="Products">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              mr={2}
            >
              {card()}
              {newItem()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}
