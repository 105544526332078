import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import jwt_decode from 'jwt-decode'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import EventIcon from '@material-ui/icons/Event'
import PolicyIcon from '@material-ui/icons/Policy'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import MovieFilterIcon from '@material-ui/icons/MovieFilter'
import PublicIcon from '@material-ui/icons/Public'

import SneakerIcon from '../../../components/Icons/SneakerIcon'
import NavItem from './NavItem'
import { getCookieProp } from '../../../utils/cookie'
import theme from '../../../theme/theme'

const items = [
  {
    href: '/app/home',
    icon: HomeIcon,
    title: 'Hem'
  },

  {
    href: '/app/discovery',
    icon: PublicIcon,
    title: 'Utforska'
  },

  {
    href: '/app/sneakers',
    icon: SneakerIcon,
    title: 'Sneakers'
  },
  {
    href: '/app/news',
    icon: LocalLibraryIcon,
    title: 'Nyheter'
  },
  {
    href: '/app/events',
    icon: EventIcon,
    title: 'Event'
  },
  {
    href: '/app/shows',
    icon: MovieFilterIcon,
    title: 'Program'
  },
  {
    href: '/app/settings',
    icon: PolicyIcon,
    title: 'Policy'
  }
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()

  const onMobileCloseRef = useRef(() => {})

  onMobileCloseRef.current = () => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }

  const getToken = () => {
    let token
    try {
      token = jwt_decode(getCookieProp('token'))
    } catch (e) {
      token = 'bearer null'
    }

    return token
  }

  useEffect(() => {
    onMobileCloseRef.current()
  }, [location.pathname])

  var decoded = getToken()
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} src={decoded.picture} />
        <Typography
          className={classes.name}
          style={{ color: theme.palette.primary.main }}
          variant="h5"
        >
          {decoded.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {decoded.role}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
}

export default NavBar
