import React, { useRef, useState, useEffect } from 'react'
import { styled, withTheme } from '@material-ui/core/styles'

import {
  Collapse,
  Paper,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Container,
  makeStyles,
  IconButton
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import clsx from 'clsx'

import Modal from '../../../components/modal/modal'
import AddModalContent from 'src/components/modal/AddModalContent'
import ConfirmModalContent from 'src/components/modal/ConfirmModalContent'
import CustomButton from 'src/components/buttons/CustomButton'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'

import { SETTINGS } from '../../../utils/pageSettings'
import { formatDate } from '../../../utils/functions'
import { endpoints } from '../../../utils/API_endpoints'
import theme from '../../../theme/theme'
import useHttp from '../../../hooks/http'
import PollModal from '../../../components/modal/PollModal'
import { CommentModalContent } from 'src/components/modal/CommentModalContent'

const Circle = styled(withTheme(Card))((props) => ({
  width: 12,
  height: 12,
  borderRadius: 12 / 2,
  border: '0.3px black',
  backgroundColor: props.active
    ? props.theme.palette.text.secondary
    : props.theme.palette.text.white,
  boxShadow: '6px 4px 35px -17px rgba(0,0,0,0.75)',
  '&:not(:first-child)': {
    marginLeft: props.theme.spacing(1)
  }
}))

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  productCard: {},
  cardContainer: {
    backgroundColor: theme.palette.background.default,
    height: (props) => props.height,
    maxWidth: '100%',
    minHeight: '800px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'ease-in-out .6s'
  },
  paperOverlay: {
    height: '50%',
    background: `linear-gradient(${theme.palette.background.default} 0%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%)`,
    zIndex: '999'
  },
  paper: {
    backgroundColor: theme.palette.background.default,

    border: 'none',
    boxShadow: 'none'
  },
  media: {
    maxHeight: '300px'
  },
  pictureSlidesContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  arrowBackward: {
    top: '50%',
    left: '0',
    position: 'absolute'
  },
  arrowForward: {
    top: '50%',
    right: '0',
    position: 'absolute'
  },
  circleCentering: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  circleContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    bottom: '10px',
    width: '100%'
  },

  chartContainer: {
    marginTop: theme.spacing(2)
  },
  adBanner: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: theme.spacing(7),
    position: 'absolute',
    textAlign: 'center',
    zIndex: '1000',
    '& > p': {
      color: theme.palette.primary.light
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.complex
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  openCardActionContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row'
  }
}))
export default function NewsCard({
  putNews,
  deleteNews,
  publishItem,
  toggleComments,
  parentHook,
  parentData,
  clearState,
  alert
}) {
  const [checked, setChecked] = React.useState(false)
  const ref = useRef()
  const upperContainer = useRef()
  const lowerContainerRef = useRef()
  const textRef = useRef()
  const [tagsData, setTagsData] = useState(null)
  const [tags, setTags] = useState({})
  const imageRef = useRef()
  const [height, setHeight] = useState('100%')
  const [closedHeight, setClosedHeight] = useState()
  const [displayExpandableArrow, setDisplayExpandableArrow] = useState(false)
  const [responseAlertData, setResponseAlertData] = alert
  const [pictureIndex, setPictureIndex] = useState(0)
  const [displayPictures, setDisplayPictures] = useState(parentData.image)

  const classes = useStyles({
    height: checked ? '100%' : height
  })

  const collapsableStandardHeight = 300
  const {
    isLoading,
    error,
    data,
    statusCode,
    sendRequest,
    clear,
    reqExtra
  } = useHttp()

  const getNewsRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const setTagsDataRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})

  getNewsRef.current = () => {
    sendRequest(`${endpoints.tags}/${parentData._id}`, 'GET', null, 'tags')
  }
  clearRef.current = () => {
    clear()
  }

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  setTagsDataRef.current = (data) => {
    setTagsData(data)
  }

  useEffect(() => {
    if (!isLoading && !error && data && reqExtra === 'tags') {
      setTagsDataRef.current(data[0])
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getNewsRef.current()

    return () => clearRef.current()
  }, [responseAlertData])

  useEffect(() => {
    setHeight(ref?.current?.clientHeight)
  }, [])

  useEffect(() => {
    setDisplayExpandableArrow(
      textRef.current.clientHeight > collapsableStandardHeight
    )
  }, [textRef])

  useEffect(() => {
    let failsafeHeigt = 0
    if (
      !isNaN(height) &&
      !isNaN(upperContainer.current.clientHeight) &&
      !isNaN(lowerContainerRef.current.clientHeight)
    ) {
      if (imageRef.current.clientHeight === 0) {
        failsafeHeigt += 300
      }

      setClosedHeight(
        height -
          upperContainer.current.clientHeight -
          lowerContainerRef.current.clientHeight -
          failsafeHeigt
      )
    }
  }, [height])

  useEffect(() => {
    setPictureIndex(0)
    setDisplayPictures(parentData.image)
  }, [parentData.image])

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  const putNewsTags = () => {
    sendRequest(
      `${endpoints.tags}/${parentData._id}`,
      'PUT',
      JSON.stringify(tags),
      'action'
    )
  }

  const clearNewsTagState = () => {
    setTags({})
  }

  const adBanner = () => {
    return (
      <>
        {parentData.ad.isAd && (
          <Container
            style={{
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            className={classes.adBanner}
          >
            <Typography variant="h3" component="h2">
              Annonserad nyhet
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Till {formatDate(parentData.ad.date.split('T')[0])}
            </Typography>
          </Container>
        )}
      </>
    )
  }

  const handlePast = () => {
    if (pictureIndex - 1 < 0) {
      setPictureIndex(displayPictures.length - 1)
    } else {
      setPictureIndex((pictureIndex) => pictureIndex - 1)
    }
  }

  const handleNext = () => {
    if (pictureIndex + 1 > displayPictures.length - 1) {
      setPictureIndex(0)
    } else {
      setPictureIndex((pictureIndex) => pictureIndex + 1)
    }
  }

  const renderCircles = () => {
    return displayPictures?.map((_, i) => {
      return <Circle key={i} active={i === pictureIndex ? 1 : 0}></Circle>
    })
  }

  const renderPicture = () => {
    return (
      <CardMedia
        ref={imageRef}
        component="img"
        className={classes.media}
        image={displayPictures[pictureIndex]}
        title="Picture on shoes"
      />
    )
  }

  return (
    <>
      <Card ref={ref} className={classes.cardContainer}>
        {parentData && (
          <Container ref={upperContainer} style={{ padding: 0 }}>
            <Container style={{ padding: 0, position: 'relative' }}>
              {adBanner()}
            </Container>
            <div className={classes.pictureSlidesContainer}>
              <ArrowForwardIosRoundedIcon
                onClick={handleNext}
                style={{ color: theme.palette.text.icon, opcity: '.5' }}
                className={classes.arrowForward}
              />
              {renderPicture()}
              <ArrowBackIosRoundedIcon
                onClick={handlePast}
                style={{ color: theme.palette.text.icon }}
                className={classes.arrowBackward}
              />
              <div className={classes.circleContainer}>
                <div className={classes.circleCentering}>{renderCircles()}</div>
              </div>
            </div>
            <CardContent style={{ paddingBottom: 0 }}>
              <Typography
                variant="subtitle1"
                component="h3"
                style={{ fontSize: '30px', fontWeight: '200' }}
              >
                {parentData.title ? parentData.title : ''}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="h6"
                style={{ fontSize: '15px', fontWeight: '200' }}
              >
                Visningar: {parentData.traffic}
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                component="h6"
                style={{ fontSize: '15px', fontWeight: '200' }}
              >
                Gillningar: {parentData.likes}
              </Typography>
              <Typography
                variant="subtitle1"
                component="h3"
                style={{ fontSize: '12px', fontWeight: '200' }}
              >
                {formatDate(parentData.publishedAt || parentData.createdAt)}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                component="h2"
                style={{ fontSize: '20px', fontWeight: '400' }}
              >
                {parentData.author ? parentData.author : ''}
              </Typography>
              <div className={classes.paperOverlay}>
                <Collapse
                  in={checked}
                  timeout={400}
                  collapsedHeight={closedHeight}
                >
                  <Paper elevation={4} className={classes.paper}>
                    <Typography
                      ref={textRef}
                      variant="body2"
                      component="h6"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {parentData.information ? parentData.information : ''}
                    </Typography>
                  </Paper>
                </Collapse>

                <Container className={classes.openCardActionContainer}>
                  {displayExpandableArrow && (
                    <IconButton
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: checked
                      })}
                      onClick={handleChange}
                      aria-expanded={checked}
                      aria-label="show more"
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  )}
                </Container>
              </div>
            </CardContent>
          </Container>
        )}
        {parentData && (
          <Container
            ref={lowerContainerRef}
            style={{ padding: 0, position: 'sticky' }}
          >
            <CardActions>
              <div className={classes.actionContainer}>
                <div className={classes.actionRow}>
                  <Modal
                    content={
                      <ConfirmModalContent
                        title={'Är du säker?'}
                        action={() => publishItem(parentData._id)}
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={
                          parentData.isPublished
                            ? theme.palette.primary.main
                            : theme.palette.text.secondary
                        }
                        backgroundColor={
                          parentData.isPublished
                            ? theme.palette.background.paper
                            : null
                        }
                        isDisabled={parentData.isPublished}
                        text={
                          parentData.isPublished ? 'PUBLICERAD' : 'PUBLICERA'
                        }
                      />
                    }
                  />
                  <Modal
                    content={
                      <ConfirmModalContent
                        title={'Är du säker?'}
                        action={() => deleteNews(parentData._id)}
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={theme.palette.text.secondary}
                        text={'Delete'}
                      />
                    }
                  />
                  <Modal
                    content={
                      <AddModalContent
                        title={'Redigera nyhet'}
                        parentHook={parentHook}
                        parentData={parentData}
                        inputs={SETTINGS.NEWS}
                        action={putNews}
                        clearState={clearState}
                        isPublished={parentData?.isPublished}
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={theme.palette.text.secondary}
                        text={'Edit'}
                      />
                    }
                  />
                  <Modal
                    content={
                      <AddModalContent
                        title={'Lägg till sökord'}
                        inputs={SETTINGS.TAGS}
                        parentHook={[tags, setTags]}
                        parentData={tagsData}
                        action={putNewsTags}
                        clearState={clearNewsTagState}
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={theme.palette.text.secondary}
                        text={'Taggar'}
                      />
                    }
                  />
                </div>
                <div className={classes.actionRow}>
                  <Modal
                    content={
                      <ConfirmModalContent
                        title={'Är du säker?'}
                        action={() =>
                          toggleComments(
                            parentData._id,
                            parentData.commentsDisabled
                          )
                        }
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={theme.palette.text.secondary}
                        text={
                          parentData.commentsDisabled
                            ? 'GÖR KOMMENTARER PUBLIKA'
                            : 'STÄNG AV KOMMENTARER'
                        }
                      />
                    }
                  />
                  {parentData.poll?.alternatives.length > 0 && (
                    <Modal
                      content={<PollModal parentData={parentData} />}
                      button={
                        <CustomButton
                          size={'small'}
                          color={theme.palette.text.secondary}
                          text={'Poll'}
                        />
                      }
                    />
                  )}
                  <Modal
                    content={
                      <CommentModalContent
                        title={'Kommentarer'}
                        objectId={parentData._id}
                        object
                      />
                    }
                    button={
                      <CustomButton
                        size={'small'}
                        color={theme.palette.text.secondary}
                        text={'Kommentarer'}
                      />
                    }
                  />
                </div>
              </div>
            </CardActions>
          </Container>
        )}
      </Card>
    </>
  )
}
