import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Input,
  FormHelperText,
  FormControl,
  InputLabel,
  Link
} from '@material-ui/core'
import CustomChip from '../reusable/CustomChip'
import PlusIcon from '../reusable/plusIcon'
import theme from '../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },

  textArea: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.2)
    }
  },
  multilineColor: {
    color: theme.palette.primary.main
  }
}))

export default function TextMultipleInput({
  id,
  label,
  helper,
  placeholder,
  parentHook,
  parentData,
  variant,
  required
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [text, setText] = useState('')
  const [chipsData, setChipsData] = useState([])
  const [error, setError] = useState(false)
  const [localHelper, setLocalHelper] = useState()
  const [click, setClick] = useState(true)

  const setParentStateRef = useRef(() => {})

  setParentStateRef.current = () => {
    setParentState({
      ...parentState,
      [label.eng]: chipsData
    })
  }

  const itemsWhereChipDisplayText = ['reseller', 'size', 'tags', 'tickets']
  const handleChange = (event) => {
    setText(event.target.value)
  }

  useEffect(() => {
    return () => {
      setError(false)
    }
  }, [])

  useEffect(() => {
    setParentStateRef.current()
  }, [click])

  useEffect(() => {
    if (text === '') {
      setError(false)
      setLocalHelper(helper.text)
    }
  }, [text, helper.text])

  const renderChips = () => {
    return chipsData?.map((data) => {
      return (
        <CustomChip
          key={data}
          required={required}
          image={data}
          label={
            itemsWhereChipDisplayText.includes(label.eng) && trimText(data)
          }
          handleDelete={() => handleDelete(data)}
        />
      )
    })
  }

  const handleDelete = (chipToDelete) => () => {
    setClick((prevState) => !prevState)

    setChipsData((currentChips) =>
      currentChips.filter((data) => data !== chipToDelete)
    )
  }

  const checkIfValueExistInState = (item) => {
    return chipsData.filter((data) => data === item).length === 0
  }

  const trimText = (text) => {
    const copy = text
    let ending = copy.length >= 20 ? '...' : ''
    return copy.slice(0, 20) + ending
  }

  const checkIfNotValidLink = (src) => {
    return src.slice(0, 4) !== 'http'
  }

  const addChip = (src) => {
    if (src === '') return
    if (!checkIfValueExistInState(src)) {
      setError(true)
      setLocalHelper(helper.error)
    } else if (
      !itemsWhereChipDisplayText.includes(label.eng) &&
      checkIfNotValidLink(src)
    ) {
      setError(true)
      setLocalHelper('Fel typ av länk, testa att kopiera om länken')
    } else {
      setChipsData((prevData) => [...prevData, src])
      setClick((prevState) => !prevState)
      setText('')
    }
  }

  useEffect(() => {
    parentData &&
      parentData[label.eng]?.forEach((link) => {
        setChipsData((prevData) => [...prevData, link])
      })
  }, [parentData, label.eng])

  const renderInput = () => {
    return variant === 'standard' ? (
      <Input
        error={error}
        id={id}
        placeholder={placeholder}
        fullWidth
        value={text}
        label={label.swe}
        style={{ paddingRight: 0 }}
        className={classes.textField}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              style={{ color: theme.palette.primary.main }}
              onClick={() => addChip(text)}
            >
              <PlusIcon size={25} />
            </IconButton>
          </InputAdornment>
        }
        // labelWidth={70}
      />
    ) : (
      <OutlinedInput
        error={error}
        id={id}
        placeholder={placeholder}
        fullWidth
        value={text}
        label={label.swe}
        style={{ paddingRight: 0 }}
        className={classes.textField}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              style={{ color: theme.palette.primary.main }}
              onClick={() => addChip(text)}
            >
              <PlusIcon size={25} />
            </IconButton>
          </InputAdornment>
        }
        // labelWidth={70}
      />
    )
  }

  const renderLinkToImgur = () => {
    if (!itemsWhereChipDisplayText.includes(label.eng)) {
      return (
        <Link target="blank" href="https://imgur.com/upload">
          här
        </Link>
      )
    }
  }

  return (
    <div className={classes.root}>
      <FormControl
        required={required}
        className={clsx(classes.textArea)}
        variant={variant}
      >
        <InputLabel
          htmlFor="outlined-adornment-password"
          style={{ color: theme.palette.primary.main }}
        >
          {label.swe}
        </InputLabel>
        {renderInput()}
        <FormHelperText>
          {localHelper} {renderLinkToImgur()}
        </FormHelperText>
      </FormControl>
      <div className={classes.chipContainer}>{renderChips()}</div>
    </div>
  )
}
