import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { setCookie } from '../../utils/cookie'
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Collapse
} from '@material-ui/core'

import Page from 'src/components/Page'
import useHttp from '../../hooks/http'
import { endpoints } from '../../utils/API_endpoints'
import { auth } from '../../utils/firebase'
import theme from '../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  multilineColor: {
    color: theme.palette.primary.main,
    '& > p': {
      color: 'red'
    }
  }
}))

const LoginView = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [customError, setError] = useState('')
  const { isLoading, error, statusCode, data, sendRequest } = useHttp()
  const [, setShowSpinner] = useState(false)
  const navigateRef = useRef()
  const ESCAPE_KEY = 13

  const navigate = useNavigate()

  navigateRef.current = () => {
    navigate('/app/home', { replace: true })
  }

  useEffect(() => {
    if (!isLoading && !error && data) {
      if (statusCode === 200) {
        const { token } = data
        setCookie('token', token)
        setShowSpinner(false)
        navigateRef.current()
      }
      if (data.error) {
        setError(data.message)
      }
    }
    if (error) {
      setError('Ogitligt Användarnamn eller lösenord')
    }
  }, [data, error, isLoading, statusCode])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  })

  const handleKeyDown = (event) => {
    if (event.keyCode === ESCAPE_KEY) {
      login()
    }
  }

  const login = () => {
    setShowSpinner(true)
    email.length > 0 && password.length > 0
      ? auth
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            sendRequest(
              `${endpoints.auth}/loginadmin`,
              'POST',
              JSON.stringify({
                email: user.user.email,
                user_id: user.user.uid
              }),
              'post'
            )
          })
          .catch(() => {
            setError('Fel email eller lösenord')
            setShowSpinner(false)
          })
      : setError('Vänligen fyll i både lösenord & email')
  }

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container
          maxWidth="sm"
          style={{
            backgroundColor: theme.palette.background.paper,
            padding: '50px',
            borderRadius: '10px'
          }}
        >
          <Box mb={3} display="flex" justifyContent="center">
            <Typography
              style={{ color: theme.palette.primary.main }}
              variant="h2"
            >
              Logga in för att fortsätta
            </Typography>
          </Box>
          <TextField
            fullWidth
            required
            label="Email "
            margin="normal"
            name="email"
            onChange={(event) => setEmail(event.target.value)}
            type="email"
            variant="outlined"
            inputProps={{
              className: classes.multilineColor,
              style: {
                color: theme.palette.primary.main,
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset `
              }
            }}
            InputLabelProps={{
              style: { color: theme.palette.primary.main }
            }}
          />
          <TextField
            fullWidth
            required
            label="Lösenord"
            margin="normal"
            name="password"
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            variant="outlined"
            inputProps={{
              className: classes.multilineColor,
              style: {
                color: theme.palette.primary.main,
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset `
              }
            }}
            InputLabelProps={{
              style: { color: theme.palette.primary.main }
            }}
          />
          <Collapse in={customError.length > 0} timeout="auto" unmountOnExit>
            <Typography component="p" color="error">
              {customError}
            </Typography>
          </Collapse>
          <Box my={2}>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => login()}
            >
              Logga in
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

export default LoginView
