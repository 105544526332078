import React from 'react'
import {
  makeStyles,
  CardMedia,
  CardContent,
  Container,
  Typography
} from '@material-ui/core/'

const useStyles = makeStyles({
  root: {
    padding: 0
  },

  pictureSlidesContainer: {
    objectFit: 'cover',
    height: '300px',
    width: '100%',
    overflow: 'hidden'
  }
})

export default function ThirdStepPrimary({ body }) {
  const classes = useStyles()

  return (
    <Container style={{ padding: 0 }} className={classes.root}>
      <CardMedia
        className={classes.pictureSlidesContainer}
        image={body.image}
        title="Bild på objekt"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {body.type}
        </Typography>
        <Typography variant="h2" component="h2">
          {body.title}
        </Typography>
      </CardContent>
    </Container>
  )
}
