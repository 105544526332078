import React from 'react'

const Logo = (props) => {
  return (
    <img
      width="45px"
      alt="Logo"
      src="/static/images/third_icon@3x.png"
      {...props}
    />
  )
}

export default Logo
