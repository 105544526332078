import React, { useEffect, useRef } from 'react'
import { TYPE_CONTENT } from '../inputTypes'
import { makeStyles, Container, Button } from '@material-ui/core/'
import Typography from '@material-ui/core/Typography'
import { useDynamicRefs } from 'src/hooks/useDynamicRefs'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2)
  },
  typography: {
    paddingTop: theme.spacing(4),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  paper: {
    width: '80%',
    maxWidth: '80%',
    height: 'auto',
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    overflowY: 'scroll',
    '& ::-webkit-scrollbar': {
      display: 'none'
    }
  },
  modalContainer: {
    display: 'flex',
    padding: theme.spacing(5),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'hidden',
    '& ::-webkit-scrollbar': {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      maxHeight: '100%',
      borderRadius: theme.spacing(0)
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      maxHeight: '80%'
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      maxHeight: '70%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      maxHeight: '80%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '35%',
      maxHeight: '65%'
    },

    boxShadow: theme.shadows[5],
    // paddingLeft: theme.spacing(5),
    // paddingRight: theme.spacing(5),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  form: {
    overflowY: 'scroll',
    '& ::-webkit-scrollbar': {
      display: 'none'
    },
    width: '100%'
  }
}))

export default function AddModalContent({
  inputs,
  title,
  parentHook,
  parentData,
  action,
  setOpen,
  open,
  clearState,
  isPublished
}) {
  const classes = useStyles()
  const [getRef] = useDynamicRefs()
  const formRef = useRef()
  const clearRef = useRef(() => {})

  clearRef.current = () => {
    clearState()
  }

  const renderContent = (inputs) => {
    if (isPublished) {
      delete inputs['PUBLISH_DATE']
    }
    return Object.values(inputs).map((input) => {
      return TYPE_CONTENT.find(({ type }) => type === input.type).component(
        input,
        parentHook,
        parentData,
        formRef
      )
    })
  }

  useEffect(() => {
    clearRef.current()
  }, [open])

  const fetch = () => {
    let allFieldsFilled = true
    Object.values(inputs).forEach(({ content }) => {
      getRef(content.eng)?.current?.reportValidity()
      if (getRef(content.eng)?.current?.reportValidity() === false) {
        allFieldsFilled = false
      }
    })

    if (allFieldsFilled) {
      action()
      setOpen(false)
    }
  }

  return (
    <div className={classes.modalContainer}>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h2"
        component="h1"
      >
        {title}
      </Typography>
      <div className={classes.paper}>{renderContent(inputs)}</div>
      <Container className={classes.actionContainer}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={() => setOpen(false)}
        >
          {'Avbryt'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={fetch}
        >
          {'Spara'}
        </Button>
      </Container>
    </div>
  )
}
