import * as React from 'react'

const map = new Map()

const setRef = (key) => {
  if (!key) return null
  const ref = React.createRef()
  map.set(key, ref)
  return ref
}

const getRef = (key) => {
  if (!key) return null
  return map.get(key)
}

export const useDynamicRefs = () => {
  return [getRef, setRef]
}
