import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Container, Typography, makeStyles } from '@material-ui/core'
import Page from 'src/components/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  errorMessageContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  code: {
    fontSize: '10rem'
  }
}))

const NotAuthenticatedView = () => {
  const classes = useStyles()
  const TITLE = 'Du måste logga in för att se denna resursen'
  const MESSAGE = 'För att logga in klicka '
  const ACTION_MESSAGE = 'här'
  const CODE = '401'

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            {TITLE}
          </Typography>
          <Container className={classes.errorMessageContainer}>
            <Typography align="left" color="textPrimary" variant="subtitle2">
              {MESSAGE}
            </Typography>
            <Box p={0.3} />
            <RouterLink to="/login">
              <Typography align="right" color="secondary" variant="subtitle2">
                {ACTION_MESSAGE}
              </Typography>
            </RouterLink>
          </Container>
          <Box textAlign="center">
            <Typography
              className={classes.code}
              variant="h1"
              component="h2"
              color="secondary"
              gutterBottom
            >
              {CODE}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

export default NotAuthenticatedView
