import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
const { REACT_APP_FIREBASE_CONFIG } = process.env

firebase.initializeApp(
  JSON.parse(Buffer.from(REACT_APP_FIREBASE_CONFIG, 'base64').toString('ascii'))
)
export const auth = firebase.auth()
export const firestore = firebase.firestore()
