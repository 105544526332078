import React, { useState, useEffect } from 'react'
import CustomButton from 'src/components/buttons/CustomButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import theme from '../../theme/theme'
import { firestore } from 'src/utils/firebase'
import CommentsList from '../reusable/CommentList'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '10px'
  },
  paper: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  listContainer: {
    height: 'auto',
    width: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255, .5)',
      outline: '1px solid slategrey'
    }
  },

  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '40%'
    },
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 5, 5),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    maxHeight: '90%'
    // marginTop: '60px'
  },
  buttonContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  buttonPrimary: {
    minWidth: '100px',
    backgroundColor: 'blue'
  },
  buttonSecondary: {
    minWidth: '100px',
    backgroundColor: 'Red'
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '4px',
    padding: '4px',
    paddingLeft: '8px',
    borderRadius: '5px',
    backgroundColor: 'lightgray',
    color: 'black'
  },
  nameAndTimeContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    paddingRight: '5px'
  },
  time: {
    fontSize: '0.8rem',
    paddingTop: '1px'
  },
  comment: {
    fontStyle: 'italic',
    fontSize: '1.2rem'
  }
}))

export const CommentModalContent = ({ title, action, setOpen, objectId }) => {
  const [comments, setComments] = useState([])
  const classes = useStyles()
  const [loading, setLoading] = useState(true)

  const getComments = async () => {
    setLoading(true)
    const commentsData = await firestore
      .collection('comments')
      .doc(objectId)
      .collection('comments')
      .get()
    const comments = commentsData.docs.map((doc) => {
      let data
      data = doc.data()
      data.id = doc.id
      return data
    })
    for (const comment of comments) {
      comment.comments = []
      let commentsOnComment = await firestore
        .collection('comments')
        .doc(objectId)
        .collection('comments')
        .doc(comment.id)
        .collection('comments')
        .get()
      let subComments = commentsOnComment.docs.map((doc) => {
        let data
        data = doc.data()
        data.id = doc.id
        data.parentId = comment.id
        return data
      })
      subComments.map((d) => {
        return comment.comments.push(d)
      })
    }
    setComments(comments)
    setLoading(false)
  }

  useEffect(() => {
    getComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId])

  return (
    <div className={classes.modalContainer}>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h2"
        component="h1"
      >
        {(loading && !comments.length) || comments.length
          ? title
          : 'Inga kommentarer'}
      </Typography>
      <div className={classes.listContainer}>
        <div className={classes.typography}>
          {comments.length > 0 ? (
            <CommentsList
              comments={comments}
              objectId={objectId}
              updateComments={getComments}
            />
          ) : loading ? (
            <p>Laddar kommentarer... </p>
          ) : null}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <CustomButton
          classname={classes.buttonPrimary}
          action={() => setOpen(false)}
          backgroundColor={theme.palette.primary.main}
          variant={'contained'}
          color={'primary'}
          text={'Avbryt'}
        ></CustomButton>
      </div>
    </div>
  )
}
