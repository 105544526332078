import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  chip: {
    color: theme.palette.secondary.main,
    '& > span': {
      color: theme.palette.primary.main
    },
    '& > svg': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      backgroundColor: 'orange'
    }
  },
  outlinedInput: {
    color: 'red',
    backgroundColor: 'blue'
  }
}))

export default function CustomChip({ label, image, handleDelete }) {
  const classes = useStyles()

  const renderChip = () => {
    if (label)
      return (
        <Chip
          variant="outlined"
          color="primary"
          className={classes.chip}
          onDelete={handleDelete(label)}
          label={label}
        />
      )
    else
      return (
        <Chip
          variant="outlined"
          color="primary"
          // outlinedPrimary
          className={classes.chip}
          onDelete={handleDelete(image)}
          avatar={<Avatar src={image} />}
        />
      )
  }

  return <div className={classes.root}>{renderChip()}</div>
}
