import React, { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Container,
  makeStyles
} from '@material-ui/core'
import theme from '../../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  multilineColor: {
    color: theme.palette.primary.main
  },
  inputContainer: {
    marginBottom: theme.spacing(2)
  },
  actionContainer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.primary.light,
    zIndex: 99999
  }
}))

const defaultValue = {
  title: '',
  text: ''
}

const Policy = ({ className, parentHook, parentData, action, ...rest }) => {
  const classes = useStyles()
  const [policy, setPolicy] = parentHook
  const setPolicyDataRef = useRef(() => {})

  setPolicyDataRef.current = () => {
    if (parentData) {
      setPolicy(parentData[0]?.policyData)
    } else {
      setPolicy([defaultValue])
    }
  }

  const buildTextBlock = (block, i) => {
    return (
      <Container key={uuidv4()} className={classes.inputContainer}>
        <TextField
          id="filled-multiline-static"
          label="Titel"
          fullWidth
          defaultValue={block.title}
          onChange={(event) => hangleChange(event, 'title', i)}
          variant="filled"
          autoComplete="off"
          inputProps={{
            className: classes.multilineColor
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.main }
          }}
        ></TextField>

        <TextField
          id="filled-multiline-static"
          label="Text"
          multiline
          fullWidth
          onChange={(event) => hangleChange(event, 'text', i)}
          rows={10}
          defaultValue={block.text}
          variant="filled"
          inputProps={{
            className: classes.multilineColor
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.main }
          }}
        ></TextField>
      </Container>
    )
  }

  const hangleChange = (event, field, i) => {
    policy[i][field] = event.target.value
    setPolicy(policy)
  }

  useEffect(() => {
    setPolicyDataRef.current()
  }, [parentData])

  const addField = (textBlock) => {
    if (policy?.length) {
      setPolicy((prevState) => [...prevState, textBlock])
    } else {
      setPolicy([textBlock])
    }
  }

  const renderTextInputContainers = () => {
    return policy?.map((block, i) => buildTextBlock(block, i))
  }

  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="Användarvillkor" title="Villkor" />
        <Divider />
        <CardContent>{renderTextInputContainers()}</CardContent>
        <Divider />
      </Card>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
        className={classes.actionContainer}
      >
        <Button
          color="primary"
          variant="contained"
          style={{ marginRight: '10px' }}
          onClick={() => addField({ title: '', text: '' })}
        >
          Skapa nytt stycke
        </Button>
        <Button color="primary" variant="contained" onClick={action}>
          Spara
        </Button>
      </Box>
    </form>
  )
}

export default Policy
