import animateScrollTo from 'animated-scroll-to'

const EasingFunctions = {
  // no easing, no acceleration
  linear: (t) => {
    return t
  },
  // accelerating from zero velocity
  easeInQuad: (t) => {
    return t * t
  },
  // decelerating to zero velocity
  easeOutQuad: (t) => {
    return t * (2 - t)
  },
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  },
  // accelerating from zero velocity
  easeInCubic: (t) => {
    return t * t * t
  },
  // decelerating to zero velocity
  easeOutCubic: (t) => {
    return --t * t * t + 1
  },
  // acceleration until halfway, then deceleration
  easeInOutCubic: (t) => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  },
  // accelerating from zero velocity
  easeInQuart: (t) => {
    return t * t * t * t
  },
  // decelerating to zero velocity
  easeOutQuart: (t) => {
    return 1 - --t * t * t * t
  },
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t) => {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
  },
  // accelerating from zero velocity
  easeInQuint: (t) => {
    return t * t * t * t * t
  },
  // decelerating to zero velocity
  easeOutQuint: (t) => {
    return 1 + --t * t * t * t * t
  },
  // acceleration until halfway, then deceleration
  easeInOutQuint: (t) => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  }
}

const customOptions = (element) => {
  const options = {
    // Indicated if scroll animation should be canceled on user action (scroll/keypress/touch)
    // if set to "false" user input will be disabled until scroll animation is complete
    cancelOnUserAction: true,

    // Animation easing function, with "easeOutCubic" as default
    easing: EasingFunctions.easeInCubic,

    // DOM element that should be scrolled
    // Example: document.querySelector('#element-to-scroll'),
    elementToScroll: element,

    // Horizontal scroll offset
    // Practical when you are scrolling to a DOM element and want to add some padding
    horizontalOffset: 0,

    // Maximum duration of the scroll animation
    maxDuration: 2000,

    // Minimum duration of the scroll animation
    minDuration: 1000,

    // Duration of the scroll per 1000px
    speed: 1200,

    // Vertical scroll offset
    // Practical when you are scrolling to a DOM element and want to add some padding
    verticalOffset: 0
  }

  return options
}

export const scrollToRef = (ref) => {
  animateScrollTo(0, customOptions(ref.current))
}
