import React from 'react'
import { Button } from '@material-ui/core'

export default function CustomButton({
  action,
  variant,
  color,
  text,
  className,
  backgroundColor,
  minWidth,
  isDisabled
}) {
  return (
    <Button
      style={{
        color: color,
        backgroundColor: backgroundColor,
        minWidth: minWidth
      }}
      variant={variant}
      className={className}
      disabled={isDisabled}
      onClick={action}
    >
      {text}
    </Button>
  )
}
