import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,

    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}))

const TotalProfit = ({ className, ...rest }) => {
  const classes = useStyles()

  const [data, setData] = useState([])

  useEffect(() => {
    const queryReport = () => {
      window.gapi.client
        .request({
          path: '/v4/reports:batchGet',
          root: 'https://analyticsreporting.googleapis.com/',
          method: 'POST',
          body: {
            reportRequests: [
              {
                dateRanges: [
                  {
                    startDate: '10daysAgo',
                    endDate: 'today'
                  }
                ],
                metrics: [
                  {
                    expression: 'ga:users'
                  }
                ],
                dimensions: [
                  {
                    name: 'ga:date'
                  }
                ]
              }
            ]
          }
        })
        .then(displayResults, console.error.bind(console))
    }
    const displayResults = (response) => {
      const queryResult = response.result.reports[0].data.rows
      const result = queryResult.map((row) => {
        const dateSting = row.dimensions[0]
        const formattedDate = `${dateSting.substring(0, 4)}
        -${dateSting.substring(4, 6)}-${dateSting.substring(6, 8)}`
        return {
          date: formattedDate,
          visits: row.metrics[0].values[0]
        }
      })
      setData(result)
    }

    queryReport()
  }, [])

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Totalt antal nedladdningar
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {data}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TotalProfit.propTypes = {
  className: PropTypes.string
}

export default TotalProfit
