import React, { useState, useEffect } from 'react'

import { SETTINGS } from '../../../utils/pageSettings'
import { makeStyles } from '@material-ui/core/styles'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import theme from '../../../theme/theme'
import { formatDate } from '../../../utils/functions'

import Modal from '../../../components/modal/modal'
import AddModalContent from 'src/components/modal/AddModalContent'
import CustomButton from 'src/components/buttons/CustomButton'
import ConfirmModalContent from 'src/components/modal/ConfirmModalContent'
import {
  Collapse,
  TableCell,
  TableRow,
  Button,
  TextField,
  Box,
  IconButton,
  Checkbox,
  Typography,
  Container,
  MenuItem
} from '@material-ui/core'
import DateInput from 'src/components/inputs/date'
import { CommentModalContent } from 'src/components/modal/CommentModalContent'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 100
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  label: {
    marginTop: theme.spacing(1)
  },
  deleteSeasonButton: {
    '&:hover': {
      textDecoration: 'underline'
    },
    color: theme.palette.background.paper
  },
  selectDropdown: { color: theme.palette.primary.main },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.9',
      '& > .MuiTableCell-root': {
        color: theme.palette.primary.main
      }
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  }
}))

export default function EpisodeCellItem({
  index,
  isItemSelected,
  isOpen,
  labelId,
  showIsPublished,
  handleClick,
  episode,
  episodes,
  handleOpened,
  formatDuration,
  renderAlert,
  handleClickUpdate,
  handleChangeEdit,
  handleEpisodeChange,
  toggleComments,
  tagsData,
  inputText,
  parentHook,
  clearEpisodeTagState,
  putEpisodeTags,
  publishItem
}) {
  const classes = useStyles()
  const [value, setValue] = useState(episode.commentsDisabled)
  const [published, setPublished] = useState(episode.isPublished)
  const [episodePublishDate, setEpisodePublishDate] = useState('')

  useEffect(() => {
    // Not my proudest moment
    const event = { target: { value: '' } }
    event.target.value = episodePublishDate.publishedAt
    handleChangeEdit(event, episode._id, 'publishedAt')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodePublishDate])

  return (
    <React.Fragment key={index}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        className={classes.tableRow}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            onClick={(event) =>
              handleClick(event, episode._id, episode.episode)
            }
            checked={isItemSelected}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpened(episode._id)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {`Avsnitt ${episode.episode}`}
        </TableCell>
        <TableCell style={{ maxWidth: '400px' }}>{episode.title}</TableCell>
        <TableCell align="right">
          {episode.publishedAt && episode.isPublished
            ? formatDate(episode.publishedAt.split('T')[0])
            : 'Ej publicerad'}
        </TableCell>
        <TableCell align="right">{formatDuration(episode.duration)}</TableCell>

        <TableCell align="right">{episode?.likes ?? 0}</TableCell>
        <TableCell align="right">{episode.views}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Container
                className={classes.headerContainer}
                style={{
                  padding: 0
                }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  style={{
                    minWidth: '131px',
                    marginRight: '20px'
                  }}
                >
                  Redigera information
                </Typography>
                {renderAlert()}

                <Button
                  style={{
                    marginLeft: '20px'
                  }}
                  onClick={() => handleClickUpdate(episode._id, episode)}
                >
                  Spara
                </Button>
              </Container>
              <Container
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Container style={{ padding: 0 }}>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Titel"
                    fullWidth
                    className={classes.margin}
                    style={{
                      marginBottom: '10px',
                      paddingRight: '20px'
                    }}
                    value={inputText[episode._id].title}
                    onChange={(event) =>
                      handleChangeEdit(event, episode._id, 'title')
                    }
                  />
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Avsnittets nr"
                    value={episode.episode}
                    onChange={(event) =>
                      handleEpisodeChange(event, episode.episode)
                    }
                    SelectProps={{
                      MenuProps: {
                        classes: {
                          paper: classes.selectDropdown
                        }
                      }
                    }}
                    helperText="Byter avsnittets plats i spellistan"
                  >
                    {Object.values(episodes)
                      .sort((a, b) => a.episode - b.episode)
                      .map((episode) => {
                        return (
                          <MenuItem
                            key={episode.episode}
                            value={episode.episode}
                          >
                            {episode.episode}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </Container>
                <Container
                  style={{
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <TextField
                    id="standard-multiline-flexible"
                    label="Beskrivning"
                    multiline
                    fullWidth
                    rowsMax={5}
                    value={inputText[episode._id].description}
                    onChange={(event) =>
                      handleChangeEdit(event, episode._id, 'description')
                    }
                  />
                  <DateInput
                    parentHook={[episodePublishDate, setEpisodePublishDate]}
                    label={{
                      swe: episode.isPublished
                        ? 'Publicerades vid'
                        : 'Publiceras vid',
                      eng: 'publishedAt'
                    }}
                    onChange={(event) =>
                      handleChangeEdit(event, episode._id, 'publishedAt')
                    }
                    dateTheme="light"
                    startValue={episode?.publishedAt}
                    disabled={episode.isPublished}
                  />

                  <div className={classes.row}>
                    <Modal
                      content={
                        <ConfirmModalContent
                          title={'Är du säker?'}
                          action={() => {
                            setPublished(true)
                            publishItem(episode._id)
                          }}
                        />
                      }
                      button={
                        <CustomButton
                          size={'small'}
                          color={
                            published
                              ? theme.palette.primary.main
                              : theme.palette.text.secondary
                          }
                          backgroundColor={
                            published ? theme.palette.background.paper : null
                          }
                          isDisabled={episode.isPublished || !showIsPublished}
                          text={
                            !showIsPublished
                              ? 'Program ej publicerat'
                              : episode.isPublished
                              ? 'PUBLICERAD'
                              : 'PUBLICERA'
                          }
                        />
                      }
                    />
                    <Modal
                      content={
                        <ConfirmModalContent
                          title={'Är du säker?'}
                          action={() => {
                            toggleComments(episode._id, value)
                            setValue(!value)
                          }}
                        />
                      }
                      button={
                        <CustomButton
                          size={'small'}
                          color={theme.palette.text.secondary}
                          text={
                            value
                              ? 'GÖR KOMMENTARER PUBLIKA'
                              : 'STÄNG AV KOMMENTARER'
                          }
                        />
                      }
                    />
                    <Modal
                      content={
                        <AddModalContent
                          title={'Lägg till sökord'}
                          inputs={SETTINGS.TAGS}
                          parentHook={parentHook}
                          parentData={tagsData ? tagsData[episode._id] : []}
                          action={() => putEpisodeTags(episode._id)}
                          clearState={clearEpisodeTagState}
                        />
                      }
                      button={
                        <CustomButton
                          size={'small'}
                          color={theme.palette.text.secondary}
                          text={'Taggar'}
                        />
                      }
                    />
                    <Modal
                      content={
                        <CommentModalContent
                          title={'Kommentarer'}
                          objectId={episode._id}
                          object
                        />
                      }
                      button={
                        <CustomButton
                          size={'small'}
                          color={theme.palette.text.secondary}
                          text={'Kommentarer'}
                        />
                      }
                    />
                  </div>
                </Container>
              </Container>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
