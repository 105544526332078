import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core'
import { Search as SearchIcon } from 'react-feather'
import Modal from '../modal/modal'
import AddModalContent from 'src/components/modal/AddModalContent'
import CustomButton from 'src/components/buttons/CustomButton'
import theme from '../../theme/theme'
const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  multilineColor: {
    color: theme.palette.primary.main
  },
  toolbarBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      '& > :first-child': {
        marginBottom: theme.spacing(2)
      },
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      '& > :first-child': {
        marginBottom: theme.spacing(2)
      },
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      '& > :first-child': {
        marginBottom: theme.spacing(0)
      },
      flexDirection: 'row'
    },
    '&:last-child': {
      marginRight: theme.spacing(3)
    }
  },
  toolbarActions: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      '& > *': {
        width: '50%'
      },
      '& > :first-child': {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
      },

      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('sm')]: {
      '& > :first-child': {
        marginRight: '20px',

        marginBottom: theme.spacing(2)
      }
    }
  },
  button: {
    minWidth: '170px',
    maxHeight: '36px'
  }
}))

const Toolbar = ({
  className,
  handleChange,
  action,
  inputs,
  clearState,
  parentHook,
  component,
  optionalButton,
  type,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.toolbarBox}>
        {component()}
        <Box className={classes.toolbarActions}>
          {optionalButton && optionalButton()}

          <Modal
            text={`Lägg till ${type}`}
            button={
              <CustomButton
                className={classes.button}
                variant="contained"
                color={theme.palette.primary.light}
                backgroundColor={theme.palette.primary.main}
                text={`Lägg till ${type}`}
              />
            }
            content={
              <AddModalContent
                clearState={clearState}
                title={`Lägg till ${type}`}
                inputs={inputs}
                parentHook={parentHook}
                action={action}
              />
            }
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  className: classes.multilineColor,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                style={{ color: theme.palette.primary.main }}
                onChange={handleChange}
                placeholder={`Sök efter ${type}`}
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string
}

export default Toolbar
