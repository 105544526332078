import React from 'react'
import { Container } from '@material-ui/core'
import { ReactComponent as Icon } from './svg/fail.svg'
const FailureIcon = () => {
  return (
    <Container>
      <Icon />
    </Container>
  )
}

export default FailureIcon
