import React, { useState, useEffect, useRef } from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import Toolbar from '../../../components/reusable/Toolbar'

import Page from 'src/components/Page'
import { endpoints } from '../../../utils/API_endpoints'
import { checkObject } from '../../../utils/functions'
import useHttp from '../../../hooks/http'
import EventCard from './EventCard'
import useStatusCodeView from '../../../hooks/useStatusCodeView'
import { SETTINGS } from '../../../utils/pageSettings'
import LinnearLoader from '../../../components/LinearLoader'
import Alert from '../../../components/reusable/Alert'

import { useDynamicRefs } from '../../../hooks/useDynamicRefs'
import { scrollToRef } from '../../../hooks/useScrollToRef'

import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  cardContainer: {
    maxWidth: '100%'
  },
  media: {
    maxHeight: '70%'
  },
  pictureSlidesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  gridContainer: {
    gridTemplateRows: 'repeat(4, 1fr)',
    gridAutoRows: '1fr'
  }
}))

const ProductList = () => {
  const classes = useStyles()
  const [APIdata, setAPIdata] = useState(null)
  const [events, setEvents] = useState({})
  const [filteredData, setFilteredData] = useState('')
  const [responseAlertData, setResponseAlertData] = useState({})
  const {
    isLoading,
    error,
    statusCode,
    data,
    clear,
    sendRequest,
    reqExtra
  } = useHttp()
  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)
  const [getRef] = useDynamicRefs()

  const getEvents = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})
  const scrollRef = useRef(() => {})

  getEvents.current = () => {
    sendRequest(endpoints.events_admin, 'GET', null, 'get')
  }

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }
  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }

  scrollRef.current = () => {
    if (getRef('scroll').current) {
      scrollToRef(getRef('scroll'))
    }
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      if (statusCode < 300) {
        setAPIdata(data)
      }
    }
    if (!isLoading && !error && data && reqExtra === 'post') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && data && reqExtra === 'put') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && data && reqExtra === 'delete') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getEvents.current()

    return () => clear()
  }, [responseAlertData, clear])

  useEffect(() => {
    scrollRef.current()
  }, [responseAlertData])

  const handleChange = (event) => {
    setFilteredData(event.target.value.toLowerCase())
  }

  const clearState = () => {
    setEvents({})
  }
  const clearResponse = () => {
    setResponseAlertData({})
  }

  const sendFilterData = () => {
    if (APIdata !== null) {
      return APIdata?.filter((item) => {
        return Object.keys(item).some((key) =>
          String(item[key]).toLowerCase().includes(filteredData)
        )
      })
    }
  }

  const postEvent = () => {
    checkObject('events', events, setEvents)
    sendRequest(endpoints.events, 'POST', JSON.stringify(events), 'post')
  }

  const putEvent = (id) => {
    if (Object.keys(events).length > 0) {
      sendRequest(
        `${endpoints.events}/${id}`,
        'PUT',
        JSON.stringify(events),
        'put'
      )
    }
  }

  const deleteEvent = (id) => {
    sendRequest(`${endpoints.events}/${id}`, 'DELETE', null, 'delete')
  }
  const toggleComments = (id, commentsDisabledState) => {
    sendRequest(
      `${endpoints.events}/${id}/toggleComments`,
      'PUT',
      JSON.stringify({ commentsDisabled: !commentsDisabledState }),
      'delete'
    )
  }

  const publishItem = (id) => {
    sendRequest(`${endpoints.events}/${id}/publish`, 'PUT', null, 'delete')
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  const renderNewsCard = () => {
    return (
      <>
        {APIdata?.error ? (
          <div></div>
        ) : (
          sendFilterData()?.map((data) => {
            return (
              <Grid
                className={classes.gridItem}
                item
                key={data._id}
                lg={4}
                md={6}
                xs={12}
              >
                <EventCard
                  deleteEvent={deleteEvent}
                  putEvent={() => putEvent(data._id)}
                  toggleComments={toggleComments}
                  publishItem={publishItem}
                  clearState={clearState}
                  parentHook={[events, setEvents]}
                  parentData={data}
                  alert={[responseAlertData, setResponseAlertData]}
                />
              </Grid>
            )
          })
        )}
      </>
    )
  }

  return (
    <>
      {isLoading && <LinnearLoader />}
      <Page className={classes.root} title="events">
        <Container maxWidth={false}>
          <Toolbar
            parentHook={[events, setEvents]}
            action={postEvent}
            inputs={SETTINGS.EVENTS}
            clearState={clearState}
            handleChange={handleChange}
            component={renderAlert}
            type="event"
          />
          <Box mt={3}>
            <Grid
              container
              direction="row"
              style={{
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridAutoRows: '1fr'
              }}
              alignItems="stretch"
              spacing={3}
            >
              {renderNewsCard()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}

export default ProductList
