import { createMuiTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#0B0B0C ',
      paper: '#131516 ',
      whitepaper: colors.common.white
    },
    primary: {
      main: '#FFD500',
      light: '#131516'
    },
    secondary: {
      main: '#FFD500'
    },

    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      white: colors.blueGrey[100]
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#131516'
        }
      },

      focused: {}
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '1px solid #131516'
        }
      }
    }
  },
  shadows,
  typography
})

export default theme
