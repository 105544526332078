import { colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const theme = {
  palette: {
    background: {
      dark: '#0B0B0C ',
      default: '#fff',
      paper: '#131516 ',
      whitepaper: colors.common.white
    },
    primary: {
      main: '#FFD500',
      light: '#131516'
    },
    secondary: {
      main: '#FFD500'
    },
    text: {
      icon: colors.blueGrey[200],
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      icon_secondary: colors.blueGrey[400],
      white: colors.blueGrey[100]
    }
  },
  shadows,
  typography
}

export default theme
