import React from 'react'
import CustomButton from 'src/components/buttons/CustomButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import theme from '../../theme/theme'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  paper: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      height: '15%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      height: '10%'
    },
    [theme.breakpoints.up('md')]: {
      width: '30%',
      height: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
      height: '10%'
    },
    [theme.breakpoints.up('xl')]: {
      width: '20%',
      height: '10%'
    },
    boxShadow: theme.shadows[5],
    padding: theme.spacing(10, 5, 10),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  buttonPrimary: {
    minWidth: '100px',
    backgroundColor: 'blue'
  },
  buttonSecondary: {
    minWidth: '100px',
    backgroundColor: 'Red'
  }
}))

export default function ConfirmContent({ title, action, setOpen }) {
  const classes = useStyles()

  const handleClick = () => {
    setOpen(false)
    action()
  }

  return (
    <div className={classes.modalContainer}>
      <Typography
        className={classes.typography}
        gutterBottom
        variant="h2"
        component="h1"
      >
        {title}
      </Typography>
      <div className={classes.buttonContainer}>
        <CustomButton
          classname={classes.buttonSecondary}
          action={handleClick}
          variant={'contained'}
          text={'Ja'}
        ></CustomButton>
        <CustomButton
          classname={classes.buttonPrimary}
          action={() => setOpen(false)}
          backgroundColor={theme.palette.primary.main}
          variant={'contained'}
          color={'primary'}
          text={'Avbryt'}
        ></CustomButton>
      </div>
    </div>
  )
}
