const errorObj = [
  {
    status: {
      code: 400,
      redirect: false
    }
  },
  {
    status: {
      code: 401,
      redirect: true
    }
  },
  {
    status: {
      code: 403,
      redirect: true
    }
  },
  {
    status: {
      code: 404,
      redirect: true
    }
  },
  {
    status: {
      code: 200,
      redirect: false
    }
  },
  {
    status: {
      code: 201,
      redirect: false
    }
  },
  {
    status: {
      code: 500,
      redirect: true
    }
  }
]

export default function useStatusCodeView(navigate) {
  const recieveCode = (code) => {
    const status = errorObj.filter(({ status }) => status.code === code)[0]
    status.status.redirect &&
      navigate(`/${status.status.code}`, { code: status.status.code })
  }

  return recieveCode
}
