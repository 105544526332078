import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'

import ShowCard from './ShowCard'
import Toolbar from '../../../components/reusable/Toolbar'
import Page from 'src/components/Page'
import useStatusCodeView from '../../../hooks/useStatusCodeView'
import LinnearLoader from '../../../components/LinearLoader'
import Alert from '../../../components/reusable/Alert'
import CustomButton from '../../../components/buttons/CustomButton'

import { SETTINGS } from '../../../utils/pageSettings'
import { endpoints } from '../../../utils/API_endpoints'
import { checkObject } from '../../../utils/functions'
import useHttp from '../../../hooks/http'
import { useWindowSize } from 'src/hooks/useWindowSize'
import { useDynamicRefs } from '../../../hooks/useDynamicRefs'
import { scrollToRef } from '../../../hooks/useScrollToRef'
import theme from '../../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

export default function ShowList() {
  const classes = useStyles()

  const [APIdata, setAPIdata] = useState(null)
  const [shows, setShows] = useState({})
  const [filteredData, setFilteredData] = useState('')
  const [responseAlertData, setResponseAlertData] = useState({})
  const [primaryOpen, setPrimaryOpen] = useState(false)
  const [, setClickedChild] = useState()
  const {
    isLoading,
    error,
    statusCode,
    data,
    clear,
    sendRequest,
    reqExtra
  } = useHttp()
  const size = useWindowSize()
  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)
  const [getRef] = useDynamicRefs()

  const sendRequestRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const scrollRef = useRef(() => {})

  sendRequestRef.current = () => {
    sendRequest(endpoints.shows_admin, 'GET', null, 'get')
  }
  clearRef.current = () => {
    clear()
  }

  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }
  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  scrollRef.current = () => {
    if (getRef('scroll').current) {
      scrollToRef(getRef('scroll'))
    }
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      if (statusCode < 300) {
        setAPIdata(data)
      }
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'post') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'put') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  const clearState = () => {
    setShows({})
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  const postShow = () => {
    checkObject('shows', shows, setShows)
    shows.date = new Date()
    shows.thumbnail = [shows.thumbnail]
    sendRequest(endpoints.shows, 'POST', JSON.stringify(shows), 'post')
  }

  const putShowAdmin = (id) => {
    sendRequest(`${endpoints.shows}/admin/${id}`, 'PUT', null, 'put')
  }

  useEffect(() => {
    sendRequestRef.current()

    return () => clearRef.current()
  }, [responseAlertData])

  useEffect(() => {
    scrollRef.current()
  }, [responseAlertData])

  const handleChange = (event) => {
    setFilteredData(event.target.value.toLowerCase())
  }

  const handleClick = () => {
    setClickedChild(undefined)
    setPrimaryOpen(!primaryOpen)
  }

  const sendFilterData = () => {
    if (APIdata !== null) {
      return APIdata?.filter((item) =>
        Object.keys(item).some((key) =>
          String(item[key]).toLowerCase().includes(filteredData)
        )
      )
    }
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }
  const optionalButton = () => {
    return (
      <CustomButton
        className={classes.button}
        variant="contained"
        action={handleClick}
        color={theme.palette.primary.light}
        backgroundColor={theme.palette.primary.main}
        text={
          primaryOpen
            ? 'Avbryt'
            : size.isTablet
            ? 'highlighta'
            : `highlighta serie`
        }
        minWidth={170}
      />
    )
  }
  const renderCard = () => {
    return (
      <>
        {APIdata?.error ? (
          <div></div>
        ) : (
          sendFilterData()?.map((data) => {
            return (
              <Grid
                item
                id={data._id}
                key={data._id}
                xs={12}
                sm={6}
                md={3}
                lg={3}
              >
                <ShowCard
                  clearState={clearState}
                  isPrimaryOpen={primaryOpen}
                  putShowAdmin={putShowAdmin}
                  parentData={data}
                />
              </Grid>
            )
          })
        )}
      </>
    )
  }
  return (
    <>
      {isLoading && <LinnearLoader />}
      <Page className={classes.root} title="Shows">
        <Container maxWidth={false}>
          <Toolbar
            clearState={clearState}
            action={postShow}
            inputs={SETTINGS.SHOWS}
            parentHook={[shows, setShows]}
            handleChange={handleChange}
            component={renderAlert}
            optionalButton={optionalButton}
            type="serie"
          />
          <Box mt={3}>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              mr={2}
            >
              {renderCard()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}
