import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, MenuItem, TextField, Container } from '@material-ui/core'
import { endpoints } from '../../../utils/API_endpoints'

import useHttp from '../../../hooks/http'

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  },
  selectDropdown: {
    color: theme.palette.primary.main,
    '&:after': { color: 'black' }
  },
  select: {
    '&:focused': { color: 'black' }
  }
}))

const types = [
  {
    value: 'sneakers',
    text: 'Sneakers',
    options: { selector: 'model', image: 'image' }
  },
  {
    value: 'shows',
    text: 'Program',
    options: { selector: 'title', image: 'thumbnail' }
  },
  {
    value: 'events',
    text: 'Event',
    options: { selector: 'tourName', image: 'image' }
  },
  {
    value: 'news',
    text: 'Nyheter',
    options: { selector: 'title', image: 'image' }
  }
]

export default function SecondStepPrimary({ setBody, isDisabled }) {
  const classes = useStyles()
  const [selectedType, setSelectedType] = useState('')
  const [APIData, setAPIData] = useState(null)
  const [epsiodes, setEpisodes] = useState([])
  const [selectedData, setSelectedData] = useState()
  const {
    isLoading,
    error,
    data,
    sendRequest,
    statusCode,
    clear,
    reqExtra
  } = useHttp()

  const getSelectedTypeRef = useRef(() => {})

  getSelectedTypeRef.current = () => {
    sendRequest(endpoints[selectedType.value], 'GET', null, 'get')
  }

  useEffect(() => {
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      setAPIData(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'episodes') {
      setEpisodes(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getSelectedTypeRef.current()

    return () => clear()
  }, [selectedType, clear])

  useEffect(() => {
    isDisabled(true)
    if (selectedData) {
      isDisabled(!selectedData[selectedType.options.selector])
    }
  }, [selectedData, isDisabled, selectedType])

  const showRequest = (id) => {
    sendRequest(
      `${endpoints[selectedType.value]}/admin/${id}`,
      'GET',
      null,
      'episodes'
    )
  }

  const handleChangeCategory = (event) => {
    setSelectedType(types.find(({ value }) => event.target.value === value))

    setBody((prevState) => ({
      ...prevState,
      type: event.target.value
    }))
  }

  const handleChangeObject = (event) => {
    let matchedObject = APIData.find(({ _id }) => event.target.value === _id)
    setBody((prevState) => ({
      ...prevState,
      title: matchedObject[selectedType.options.selector],
      image: matchedObject[selectedType.options.image][0],
      relationId: matchedObject._id
    }))
    setSelectedData(matchedObject)
    showRequest(matchedObject._id)
  }

  const handleChangeEpisode = (event) => {
    let matchedObject = epsiodes.find(({ _id }) => event.target.value === _id)
    setBody((prevState) => ({
      ...prevState,
      title: matchedObject.title,
      image: matchedObject.thumbnail,
      relationId: matchedObject.showId
    }))
  }

  const renderEpisodeSelector = () => {
    return (
      selectedType.value === 'shows' &&
      epsiodes.length > 0 && (
        <TextField
          id="demo-simple-select-2"
          label="Objekt"
          required
          select
          fullWidth
          disabled={!APIData}
          onChange={handleChangeEpisode}
          SelectProps={{
            MenuProps: {
              classes: {
                paper: classes.selectDropdown
              }
            }
          }}
        >
          {epsiodes.map((data) => (
            <MenuItem key={data._id} value={data._id}>
              {data[selectedType.options.selector]}
            </MenuItem>
          ))}
        </TextField>
      )
    )
  }

  const selectGroup = () => {
    return (
      <div className={classes.root}>
        <Container className={classes.textField}>
          <TextField
            id="demo-simple-select"
            label="Kategori"
            required
            select
            fullWidth
            onChange={handleChangeCategory}
            SelectProps={{
              className: classes.select,
              MenuProps: {
                classes: {
                  paper: classes.selectDropdown
                }
              }
            }}
          >
            {types.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.text}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="demo-simple-select-2"
            label="Objekt"
            required
            select
            fullWidth
            disabled={!APIData}
            onChange={handleChangeObject}
            SelectProps={{
              MenuProps: {
                classes: {
                  paper: classes.selectDropdown
                }
              }
            }}
          >
            {APIData ? (
              APIData.map((data) => (
                <MenuItem key={data._id} value={data._id}>
                  {data[selectedType.options.selector]}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em></em>
              </MenuItem>
            )}
          </TextField>
          {renderEpisodeSelector()}
        </Container>
      </div>
    )
  }
  return <>{selectGroup()}</>
}
