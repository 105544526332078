import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const setCookie = (key, value) => {
  cookies.set(key, value, { path: '/' })
}

export const getCookieProp = (key) => {
  let token = cookies.get(key)
  if (token === undefined) {
    return ''
  } else {
    return token
  }
}

export const clearCookie = () => {
  cookies.remove('token')
}
