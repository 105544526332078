import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Card,
  CardMedia,
  Typography,
  CardActionArea,
  Container,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  cardContainer: {
    position: 'relative',
    maxWidth: '100%'
  },
  media: {
    objectFit: 'cover',
    height: '300px',

    overflow: 'hidden',
    maxHeight: '70%'
  },
  pictureSlidesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  chartContainer: {
    marginTop: theme.spacing(2)
  },
  mainShowBanner: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '1',
    textAlign: 'center',
    '& > h2': {
      padding: theme.spacing(1),
      color: theme.palette.primary.light
    }
  }
}))

export default function ShowCard({ parentData, putShowAdmin, isPrimaryOpen }) {
  const classes = useStyles()

  const mainShowBanner = () => {
    return (
      <>
        {parentData.primary && (
          <Container style={{ padding: 0 }} className={classes.mainShowBanner}>
            <Typography variant="h4" component="h2">
              Highlightad
            </Typography>
          </Container>
        )}
      </>
    )
  }
  const media = () => (
    <CardMedia
      component="img"
      className={classes.media}
      image={parentData.thumbnail[0]}
      title="Picture on shoes"
    />
  )
  return (
    <>
      <Card key={parentData._id} className={classes.cardContainer}>
        {mainShowBanner()}
        <CardActionArea
          onClick={() => isPrimaryOpen && putShowAdmin(parentData._id)}
        >
          {isPrimaryOpen ? (
            <>{media()}</>
          ) : (
            <RouterLink to={`/app/shows/${parentData._id}`}>
              {media()}
            </RouterLink>
          )}
        </CardActionArea>
      </Card>
    </>
  )
}
