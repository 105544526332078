import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import Toolbar from '../../../components/reusable/Toolbar'
import Page from 'src/components/Page'
import NewsCard from './NewsCard'
import LinnearLoader from '../../../components/LinearLoader'
import Alert from '../../../components/reusable/Alert'

import { SETTINGS } from '../../../utils/pageSettings'
import { endpoints } from '../../../utils/API_endpoints'
import { checkObject } from '../../../utils/functions'
import useHttp from '../../../hooks/http'
import useStatusCodeView from '../../../hooks/useStatusCodeView'
import { useDynamicRefs } from '../../../hooks/useDynamicRefs'
import { scrollToRef } from '../../../hooks/useScrollToRef'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  },
  cardContainer: {
    maxWidth: '100%'
  },
  media: {
    maxHeight: '70%'
  },
  pictureSlidesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}))

const ProductList = () => {
  const classes = useStyles()
  const [APIdata, setAPIdata] = useState(null)
  const [news, setNews] = useState({})
  const [responseAlertData, setResponseAlertData] = useState({})
  const {
    isLoading,
    error,
    statusCode,
    data,
    sendRequest,
    reqExtra,
    clear
  } = useHttp()
  const [filteredData, setFilteredData] = useState('')
  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)
  const [getRef] = useDynamicRefs()

  const scrollRef = useRef(() => {})
  const getNewsRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})

  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }
  getNewsRef.current = () => {
    sendRequest(endpoints.news_admin, 'GET', null, 'get')
  }
  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  scrollRef.current = () => {
    if (getRef('scroll').current) {
      scrollToRef(getRef('scroll'))
    }
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      if (statusCode < 300) {
        setAPIdata(data)
      }
    }
    if (!isLoading && !error && data && reqExtra === 'post') {
      scrollRef.current()
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && data && reqExtra === 'put') {
      scrollRef.current()
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && data && reqExtra === 'delete') {
      scrollRef.current()
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, statusCode, isLoading, reqExtra])

  const clearState = () => {
    setNews({})
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  useEffect(() => {
    getNewsRef.current()
    return () => clear()
  }, [responseAlertData, clear])

  const postNews = () => {
    checkObject('news', news, setNews)
    sendRequest(
      endpoints.news,
      'POST',
      JSON.stringify(checkNewsAd(news)),
      'post'
    )
  }

  const putNews = (id) => {
    if (Object.keys(news).length > 0) {
      sendRequest(`${endpoints.news}/${id}`, 'PUT', JSON.stringify(news), 'put')
    }
  }

  const deleteNews = (id) => {
    sendRequest(`${endpoints.news}/${id}`, 'DELETE', null, 'delete')
  }

  const toggleComments = (id, commentsDisabledState) => {
    sendRequest(
      `${endpoints.news}/${id}/toggleComments`,
      'PUT',
      JSON.stringify({ commentsDisabled: !commentsDisabledState }),
      'delete'
    )
  }

  const publishItem = (id) => {
    sendRequest(`${endpoints.news}/${id}/publish`, 'PUT', null, 'delete')
  }

  const handleChange = (event) => {
    setFilteredData(event.target.value.toLowerCase())
  }

  const checkNewsAd = (obj) => {
    if (!obj.ad) {
      obj.ad = {
        isAd: false,
        date: ''
      }
    }

    return obj
  }

  const sendFilterData = () => {
    if (APIdata !== null) {
      return APIdata?.filter((item) => {
        return Object.keys(item).some((key) =>
          String(item[key]).toLowerCase().includes(filteredData)
        )
      })
    }
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  const renderNewsCard = () => {
    return (
      <>
        {APIdata?.error ? (
          <div></div>
        ) : (
          sendFilterData()?.map((data) => {
            return (
              <Grid
                className={classes.gridItem}
                item
                key={data._id}
                lg={4}
                md={6}
                xs={12}
              >
                <NewsCard
                  deleteNews={deleteNews}
                  putNews={() => putNews(data._id)}
                  toggleComments={toggleComments}
                  publishItem={publishItem}
                  clearState={clearState}
                  parentHook={[news, setNews]}
                  parentData={data}
                  alert={[responseAlertData, setResponseAlertData]}
                />
              </Grid>
            )
          })
        )}
      </>
    )
  }

  return (
    <>
      {isLoading && <LinnearLoader />}

      <Page className={classes.root} title="News">
        <Container maxWidth={false}>
          <Toolbar
            parentHook={[news, setNews]}
            action={postNews}
            inputs={SETTINGS.NEWS}
            clearState={clearState}
            handleChange={handleChange}
            component={renderAlert}
            type="nyhet"
          />
          <Box mt={3}>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{
                gridAutoRows: '1fr'
              }}
            >
              {renderNewsCard()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}

export default ProductList
