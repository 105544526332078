import React, { useState, useEffect, useRef, forwardRef } from 'react'
import {
  makeStyles,
  FormControl,
  Container,
  Typography,
  TextField
} from '@material-ui/core'
import theme from '../../theme/theme'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { useDynamicRefs } from 'src/hooks/useDynamicRefs'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('sv', sv)
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    margin: theme.spacing(1),
    width: '100%'
  },
  multilineColor: {
    color: theme.palette.primary.main
  },
  wrapper: {
    width: '98%',
    zIndex: '100'
  },
  dateContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  radioButtonContianer: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioButtonLabel: {
    color: theme.palette.primary.main,
    fontSize: '12pz'
  }
}))

export default function DateInput({
  label,
  parentHook,
  parentData,
  helper,
  required,
  variant
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [localHelper, setLocalHelper] = useState()
  const [selectedDate, handleDateChange] = useState(null)
  const [value, setValue] = useState('full')

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  const [, setRef] = useDynamicRefs()

  const setParenStateRef = useRef(() => {})

  setParenStateRef.current = () => {
    // if (!parentData) {
    setParentState({
      ...parentState,
      [label.eng]: {
        date: selectedDate !== null ? selectedDate : '',
        type: value
      }
    })
    // }
  }

  useEffect(() => {
    return () => handleDateChange(null)
  }, [])

  useEffect(() => {
    setParenStateRef.current()
  }, [selectedDate, value])

  useEffect(() => {
    setLocalHelper(helper?.text)
  }, [helper])

  useEffect(() => {
    if (parentData) {
      handleDateChange(
        parentData[label.eng].date === '' ? null : parentData[label.eng].date
      )
      setValue(parentData[label.eng].type)
    }
  }, [parentData, label.eng])

  const renderRadioButtons = () => {
    return (
      selectedDate && (
        <Container>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {' '}
              <Typography
                style={{ fontSize: 12 }}
                className={classes.multilineColor}
              >
                Vilken del av datumet ska visas
              </Typography>
            </FormLabel>
            <RadioGroup
              className={classes.radioButtonContianer}
              aria-label="gender"
              name="gender1"
              value={value || parentData[label.eng].type}
              onChange={handleChange}
            >
              <FormControlLabel
                value={'full'}
                control={<Radio />}
                label={
                  <Typography
                    style={{ fontSize: 12 }}
                    className={classes.multilineColor}
                  >
                    Hela
                  </Typography>
                }
              />
              <FormControlLabel
                value={'month'}
                control={<Radio />}
                label={
                  <Typography
                    style={{ fontSize: 12 }}
                    className={classes.multilineColor}
                  >
                    År och Månad
                  </Typography>
                }
              />
              <FormControlLabel
                value={'year'}
                control={<Radio />}
                label={
                  <Typography
                    style={{ fontSize: 12 }}
                    className={classes.multilineColor}
                  >
                    År
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Container>
      )
    )
  }

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <TextField
        key={label.swe}
        id={label.swe}
        required={required}
        placeholder={placeholder}
        value={value}
        margin="normal"
        label={label.swe}
        className={classes.textField}
        helperText={localHelper}
        onClick={onClick}
        variant={variant}
        inputProps={{
          className: classes.multilineColor
        }}
        InputLabelProps={{
          style: { color: theme.palette.primary.main }
        }}
      />
    )
  )

  return (
    <Container className={classes.dateContainer}>
      <form ref={setRef(label.eng)} className={classes.container}>
        <DatePicker
          wrapperClassName={classes.wrapper}
          showTimeSelect
          className={classes.textField}
          locale="sv"
          title={localHelper}
          selected={
            typeof selectedDate === 'string'
              ? new Date(selectedDate)
              : selectedDate
          }
          onChange={handleDateChange}
          timeIntervals={5}
          fixedHeight
          dateFormat="yyyy MMMM d, HH:mm"
          customInput={<ExampleCustomInput text={label.swe} />}
        />
      </form>
      {renderRadioButtons()}
    </Container>
  )
}
