import React, { useState, useEffect, useRef } from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'

import Page from 'src/components/Page'
import Toolbar from '../../../components/reusable/Toolbar'
import SneakerCard from './SneakerCard'
import LinnearLoader from '../../../components/LinearLoader'
import useStatusCodeView from '../../../hooks/useStatusCodeView'
import Alert from '../../../components/reusable/Alert'
import useHttp from '../../../hooks/http'

import { endpoints } from '../../../utils/API_endpoints'
import { useNavigate } from 'react-router-dom'
import { SETTINGS } from '../../../utils/pageSettings'
import { useDynamicRefs } from '../../../hooks/useDynamicRefs'
import { scrollToRef } from '../../../hooks/useScrollToRef'
import { checkObject } from '../../../utils/functions'
// import { object } from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  cardContainer: {
    maxWidth: '100%'
  },
  gridContainer: {},
  media: {
    maxHeight: '70%'
  },
  pictureSlidesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '400px',
    overflow: ' hidden'
  },
  pictureSlides: {
    height: '100%',
    width: '100%'
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 9999
  }
}))

const ProductList = () => {
  const [APIdata, setAPIdata] = useState(null)
  const {
    isLoading,
    error,
    data,
    sendRequest,
    statusCode,
    clear,
    // reqIdentifier,
    reqExtra
  } = useHttp()
  const classes = useStyles()
  const [sneaker, setSneaker] = useState({})
  const [filteredData, setFilteredData] = useState('')
  const [responseAlertData, setResponseAlertData] = useState({})
  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)
  const [getRef] = useDynamicRefs()

  const getSneakersRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const scrollRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})

  getSneakersRef.current = () => {
    sendRequest(endpoints.sneakers_admin, 'GET', null, 'get')
  }

  clearRef.current = () => {
    clear()
  }

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }
  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }
  scrollRef.current = () => {
    if (getRef('scroll').current) {
      scrollToRef(getRef('scroll'))
    }
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      if (statusCode < 300) {
        setAPIdata(data)
      }
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'post') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'put') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'delete') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getSneakersRef.current()
    return () => clearRef.current()
  }, [responseAlertData])

  useEffect(() => {
    scrollRef.current()
  }, [responseAlertData])

  const postSneaker = () => {
    checkObject('sneakers', sneaker, setSneaker)
    sendRequest(endpoints.sneakers, 'POST', JSON.stringify(sneaker), 'post')
  }

  const putSneaker = (id) => {
    if (Object.keys(sneaker).length > 0) {
      sendRequest(
        `${endpoints.sneakers}/${id}`,
        'PUT',
        JSON.stringify(sneaker),
        'put'
      )
    }
  }

  const deleteSneaker = (id) => {
    sendRequest(`${endpoints.sneakers}/${id}`, 'DELETE', null, 'delete')
  }
  const toggleComments = (id, commentsDisabledState) => {
    sendRequest(
      `${endpoints.sneakers}/${id}/toggleComments`,
      'PUT',
      JSON.stringify({ commentsDisabled: !commentsDisabledState }),
      'delete'
    )
  }

  const publishItem = (id) => {
    sendRequest(`${endpoints.sneakers}/${id}/publish`, 'PUT', null, 'delete')
  }

  const clearState = () => {
    setSneaker({})
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  const handleChange = (event) => {
    setFilteredData(event.target.value.toLowerCase())
  }

  const sendFilterData = () => {
    return APIdata?.filter((item) => {
      return Object.keys(item).some((key) =>
        String(item[key]).toLowerCase().includes(filteredData)
      )
    })
  }

  const renderCard = () => {
    return (
      <>
        {sendFilterData()?.map((data) => {
          return (
            <Grid
              item
              id={data._id}
              key={data._id}
              xs={12}
              sm={6}
              md={6}
              lg={true}
              xl={3}
            >
              <SneakerCard
                deleteSneaker={deleteSneaker}
                putSneaker={() => putSneaker(data._id)}
                toggleComments={toggleComments}
                publishItem={publishItem}
                clearState={clearState}
                parentHook={[sneaker, setSneaker]}
                parentData={data}
                alert={[responseAlertData, setResponseAlertData]}
              />
            </Grid>
          )
        })}
      </>
    )
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  return (
    <>
      {isLoading && <LinnearLoader />}
      <Page className={classes.root} title="Products">
        <Container maxWidth={false}>
          <Toolbar
            clearState={clearState}
            action={postSneaker}
            inputs={SETTINGS.SNEAKERS}
            parentHook={[sneaker, setSneaker]}
            handleChange={handleChange}
            component={renderAlert}
            type="sneaker"
          />
          <Box mt={3}>
            <Grid
              className={classes.gridContainer}
              container
              spacing={2}
              mr={2}
            >
              {renderCard()}
            </Grid>
          </Box>
        </Container>
      </Page>
    </>
  )
}

export default ProductList
