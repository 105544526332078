import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from '../../theme/theme'
import CustomButton from '../buttons/CustomButton'
import { TextField } from '@material-ui/core'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('sv', sv)
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  wrapper: {
    width: '98%'
  },
  textField: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1),
    width: '100%'
  },
  multilineColor: {
    color: theme.palette.primary.main
  }
}))
export default function Ad({
  id,
  label,
  parentHook,
  parentData,
  helper,
  required,
  variant,
  formRef
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [localHelper, setLocalHelper] = useState()
  const [selectedDate, handleDateChange] = useState(null)
  const [open, setOpen] = useState(false)

  const setParenStateRef = useRef(() => {})

  setParenStateRef.current = () => {
    setParentState({
      ...parentState,
      [label.eng]: { isAd: open, date: selectedDate }
    })
  }

  const handleClick = () => {
    handleDateChange(null)
    setOpen((prevOpen) => !prevOpen)
  }

  useEffect(() => {
    setParenStateRef.current()
  }, [selectedDate, open])

  useEffect(() => {
    return () => handleDateChange(null)
  }, [])

  useEffect(() => {
    setLocalHelper(helper?.text)
  }, [helper])

  useEffect(() => {
    if (parentData) {
      handleDateChange(
        parentData[label.eng].date === '' ? null : parentData[label.eng].date
      )
      setOpen(parentData[label.eng].isAd)
    }
  }, [parentData, label.eng])

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <TextField
        key={label.swe}
        id={label.swe}
        required={required}
        placeholder={placeholder}
        value={value}
        margin="normal"
        label={label.swe}
        className={classes.textField}
        helperText={localHelper}
        onClick={onClick}
        variant={variant}
        inputProps={{
          className: classes.multilineColor
        }}
        InputLabelProps={{
          style: { color: theme.palette.primary.main }
        }}
      />
    )
  )

  const renderContent = () => {
    return (
      <form className={classes.container}>
        <DatePicker
          wrapperClassName={classes.wrapper}
          className={classes.textField}
          locale="sv"
          title={localHelper}
          minDate={new Date()}
          selected={
            typeof selectedDate === 'string'
              ? new Date(selectedDate)
              : selectedDate
          }
          onChange={handleDateChange}
          fixedHeight
          dateFormat="yyyy MMMM d, HH:mm"
          customInput={<ExampleCustomInput text={label.swe} />}
        />
      </form>
    )
  }
  return (
    <>
      <CustomButton
        action={handleClick}
        text={'Skapa som annons'}
        color={theme.palette.primary.main}
      />
      {open && renderContent()}
    </>
  )
}
