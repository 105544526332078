import React from 'react'
import { SvgIcon } from '@material-ui/core'

const SneakerIcon = () => {
  return (
    <SvgIcon style={{ marginRight: '8px' }}>
      <path
        className="cls-1"
        d="M1.73,5.34l-.41.37c-.45.48-.45.8,0,1.25l2.39,2.4c.11.1.21.22.32.33l-.7.7c-.47-.48-.93-1-1.4-1.43S1,8,.51,7.53a1.63,1.63,0,0,1,0-2.39C2.05,3.59,3.59,2.06,5.13.53A1.53,1.53,0,0,1,6.85.11a1.51,1.51,0,0,1,1,1.43,1.92,1.92,0,0,0,2,1.87H11a1.59,1.59,0,0,1,1.58,1c.8,1.7,1.63,3.39,2.41,5.1a1.31,1.31,0,0,0,.94.8c.51.12,1,.31,1.52.43A3.3,3.3,0,0,1,20,13.87c0,.76,0,1.51,0,2.26a1.41,1.41,0,0,1-1.41,1.41c-2.34,0-4.68,0-7,0a2.38,2.38,0,0,1-1.42-.5c-1.19-1-5.66-5.54-6.8-6.64.25-.25.45-.47.7-.7.08.07,3.57,3.54,3.67,3.65.84.83,1.68,1.66,2.51,2.51a2.2,2.2,0,0,0,1.71.72c2.11,0,4.23,0,6.35,0,.62,0,.73-.12.74-.73v-.72h-.53c-2,0-3.92,0-5.89,0a2.47,2.47,0,0,1-1.89-.8L2,5.61C1.91,5.53,1.91,5.54,1.73,5.34Z"
      />
      <path
        className="cls-1"
        d="M12.08,5.56c-.13-.28-.24-.5-.33-.73A.69.69,0,0,0,11,4.36c-.36,0-.73,0-1.09,0A3,3,0,0,1,6.82,1.56c0-.2-.15-.48-.3-.53a.77.77,0,0,0-.64.13C4.72,2.29,3.59,3.44,2.43,4.61l.13.15,9,9a1.29,1.29,0,0,0,1,.37h6.06l.4,0a2.27,2.27,0,0,0-1.5-2.35c-.62-.2-1.25-.38-1.88-.55a2,2,0,0,1-1.4-1.2c-.22-.52-.48-1-.74-1.56-.18-.37-.77-1.61-.94-2"
      />
      <path className="cls-1" d="M12.51,6.42,13,7.5" />
      <rect
        className="cls-1"
        x="9.36"
        y="5.9"
        width="4.07"
        height="1.27"
        transform="translate(-1.69 5.44) rotate(-25.07)"
      />
      <rect
        className="cls-1"
        x="10.22"
        y="7.9"
        width="4.07"
        height="1.27"
        transform="translate(-2.46 6) rotate(-25.07)"
      />
    </SvgIcon>
  )
}

export default SneakerIcon
