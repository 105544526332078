import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, makeStyles } from '@material-ui/core'

import Page from 'src/components/Page'
import Alert from '../../../components/reusable/Alert'
import Policy from './Policy'

import useStatusCodeView from '../../../hooks/useStatusCodeView'
import useHttp from '../../../hooks/http'
import { endpoints } from '../../../utils/API_endpoints'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const SettingsView = () => {
  const classes = useStyles()
  const [responseAlertData, setResponseAlertData] = useState({})
  const [APIData, setAPIData] = useState(null)
  const [policy, setPolicy] = useState([])
  const navigate = useNavigate()
  const recieveCode = useStatusCodeView(navigate)
  const {
    isLoading,
    error,
    data,
    sendRequest,
    statusCode,
    reqExtra,
    clear
  } = useHttp()

  const getPolicyRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})

  getPolicyRef.current = () => {
    sendRequest(`${endpoints.administration}/policy`, 'GET', null, 'get')
  }

  clearRef.current = () => {
    clear()
  }

  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }

  useEffect(() => {
    if (statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'get') {
      setAPIData(data)
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getPolicyRef.current()

    return () => clearRef.current()
  }, [responseAlertData])

  const putPolicy = () => {
    const body = {
      policyData: policy.filter(
        (section) => section.title !== '' && section.text !== ''
      )
    }
    sendRequest(
      `${endpoints.administration}/policy/${APIData[0]._id}`,
      'PUT',
      JSON.stringify(body),
      'action'
    )
  }

  const postPolicy = () => {
    const body = {
      policyData: policy
    }
    sendRequest(
      `${endpoints.administration}/policy`,
      'POST',
      JSON.stringify(body),
      'action'
    )
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  const selectAction = () => {
    if (APIData.length > 0) {
      return putPolicy()
    } else {
      return postPolicy()
    }
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Container style={{ position: 'sticky', top: 0, zIndex: 9999 }}>
        {renderAlert()}
      </Container>
      <Page className={classes.root} title="Settings">
        <Container maxWidth="lg">
          <Policy
            parentHook={[policy, setPolicy]}
            parentData={APIData}
            action={selectAction}
          />
        </Container>
      </Page>
    </>
  )
}

export default SettingsView
