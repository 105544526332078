import React, { useState, useEffect, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import theme from '../../../theme/theme'
import { TYPE_CONTENT } from '../../../components/inputTypes'
import { SETTINGS } from '../../../utils/pageSettings'

import EpisodeList from './EpisodeList'
import Page from 'src/components/Page'
import AddEpisodeView from './AddEpisodeView'
import PlaceholderDisplay from '../../../components/reusable/PlaceholderDisplay'
import Alert from '../../../components/reusable/Alert'
import ConfirmModalContent from 'src/components/modal/ConfirmModalContent'

import { endpoints } from '../../../utils/API_endpoints'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { useDynamicRefs } from '../../../hooks/useDynamicRefs'
import useHttp from '../../../hooks/http'
import useStatusCodeView from '../../../hooks/useStatusCodeView'
import Modal from '../../../components/modal/modal'

import {
  Tab,
  Tabs,
  Paper,
  Container,
  makeStyles,
  CardMedia,
  Typography,
  Button
} from '@material-ui/core'
import CustomButton from 'src/components/buttons/CustomButton'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  inputRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  mainContainer: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  showContainer: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row'
    }
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMediaContainer: {
    width: '100%'
  },
  media: {
    borderRadius: '5px',
    maxHeight: '350px'
  },
  buttonContainer: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 0)
    // '&:last-child': {
    //   float: 'right'
    // }
  },
  episodeButton: {
    alignContent: 'center',
    height: '100%'
  },
  label: {
    marginTop: theme.spacing(1),

    '& > p': {
      color: theme.palette.primary.main
    }
  },
  tabs: {
    minWidth: '10px',
    width: (props) => `calc(100% / ${props.tablength})`
  },
  imageContainer: {
    maxWidth: '70%',
    float: 'left'
  },
  inputContainer: {
    padding: '0px',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 2)
    },
    maxHeight: '100%'
  },
  deleteSeasonButton: {
    '&:hover': {
      textDecoration: 'underline'
    },
    color: theme.palette.primary.main
  }
}))
//TODO: på något sätt visa att serien är en waterfall
export default function ShowView() {
  const { id } = useParams()
  const [show, setShow] = useState(null)
  const [body, setBody] = useState(null)
  const [seasons, setSeasons] = useState(null)
  const [, setEpisode] = useState(null)
  const [responseAlertData, setResponseAlertData] = useState({})
  const [getRef] = useDynamicRefs()
  const {
    isLoading,
    error,
    data,
    statusCode,
    sendRequest,
    reqExtra,
    clear
  } = useHttp()
  const [value, setValue] = useState(0)
  const [viewEpisodes, setViewEpisodes] = useState(true)
  const [isContent, setIsContent] = useState(false)
  const [lastClicked, setLastClicked] = useState(0)

  const classes = useStyles({ tablength: seasons?.length })
  let navigate = useNavigate()
  const size = useWindowSize()
  const recieveCode = useStatusCodeView(navigate)

  const getShowRef = useRef(() => {})
  const getSeasonRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const recieveCodeRef = useRef(() => {})

  getShowRef.current = () => {
    sendRequest(`${endpoints.shows_admin}/${id}`, 'GET', null, 'show')
  }
  getSeasonRef.current = () => {
    sendRequest(`${endpoints.shows}/${id}/season`, 'GET', null, 'season')
  }
  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  recieveCodeRef.current = (code) => {
    recieveCode(code)
  }

  useEffect(() => {
    if (reqExtra === 'show' && statusCode) {
      recieveCodeRef.current(statusCode)
    }
    if (!isLoading && !error && data && statusCode && reqExtra === 'show') {
      setBody(null)
      setShow(data[0])
    }
    if (!isLoading && !error && data && reqExtra === 'season') {
      setViewEpisodes(true)
      setSeasons(data)
    }
    if (!isLoading && !error && data && reqExtra === 'episode') {
      setEpisode(data)
    }
    if (!isLoading && !error && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
    }
    if (!isLoading && !error && data && reqExtra === 'publish') {
      getShowRef.current()
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    getShowRef.current()

    return () => clear()
  }, [responseAlertData, clear])

  useEffect(() => {
    getSeasonRef.current()

    return () => clear()
  }, [responseAlertData, clear])

  useEffect(() => {
    if (seasons !== null) {
      if (!seasons.length) {
        setIsContent(false)
      } else if (seasons.length) {
        setIsContent(true)
      }
    }
  }, [seasons])

  const postEpisode = (body) => {
    body.episode = seasons[value].episodes.length + 1
    sendRequest(
      `${endpoints.shows}/${id}/season/${body.season}/episode`,
      'POST',
      JSON.stringify(body),
      'action'
    )
  }

  const deleteEpisode = async (season, episodesToDelete) => {
    const sortedEpisodes = episodesToDelete.sort(
      (a, b) => a.indexOfName - b.indexOfName
    )
    const actions = []
    for (let i = 0; i < sortedEpisodes.length; i++) {
      const name = sortedEpisodes[i].name
      actions.push({
        url: `${endpoints.shows}/${id}/season/${season}/episode/${name}`,
        method: 'DELETE',
        body: null,
        extra: i === episodesToDelete.length - 1 ? 'action' : null
      })
    }
    const resolvePromisesSeq = async (tasks) => {
      const results = []
      for (const task of tasks) {
        results.push(await task)
      }

      return results
    }

    const promises = actions.map(({ url, method, body, extra }) =>
      sendRequest(url, method, body, extra)
    )

    await resolvePromisesSeq(promises)
  }

  const deleteShow = () => {
    sendRequest(`${endpoints.shows}/${id}`, 'DELETE', null, 'action')
  }

  const addSeason = () => {
    if (Date.now() - lastClicked < 10000) return
    setLastClicked(Date.now())
    sendRequest(
      `${endpoints.shows}/${id}/season`,
      'POST',
      JSON.stringify({
        season: seasons.length ? seasons.length + 1 : 1
      }),
      'action'
    )
  }

  const deleteSeason = (seasonid) => {
    sendRequest(
      `${endpoints.shows}/${id}/season/${seasonid}`,
      'DELETE',
      null,
      'action'
    )
    if (value - 1 >= 0) {
      setValue((prevState) => prevState - 1)
    } else {
      setIsContent(false)
    }
  }

  const publishItem = (id) => {
    sendRequest(`${endpoints.shows}/${id}/publish`, 'PUT', null, 'publish')
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setViewEpisodes(true)
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  const renderShowInfo = () => {
    return (
      <Container className={classes.imageConatiner} style={{ padding: 0 }}>
        <CardMedia
          component="img"
          className={classes.media}
          image={show.thumbnail[0]}
          title="Picture on show"
        />
      </Container>
    )
  }
  const renderContent = (inputs) => {
    if (show?.isPublished) {
      delete inputs['PUBLISH_DATE']
    }
    return Object.values(inputs).map((input) => {
      return TYPE_CONTENT.find(({ type }) => type === input.type).component(
        input,
        [body, setBody],
        show
      )
    })
  }

  const renderTabsFromSeason = () => {
    let tabTitle = size.isMobile || seasons.length > 12 ? '' : 'Säsong'
    return seasons?.map((season) => {
      return (
        <Tab
          key={season.season}
          className={classes.tabs}
          label={`${tabTitle}  ${season.season}`}
        ></Tab>
      )
    })
  }

  const textComponent = (
    <div className={classes.label}>
      <Typography>
        Finns förnuvarande inga säsonger i den här serien, klicka på "lägg till
        säsong" för att skapa ett en säsong för denna serien. För att ta bort
        serien klicka
      </Typography>
      <RouterLink to={`/app/shows`}>
        <Typography
          className={classes.deleteSeasonButton}
          style={{ cursor: 'pointer' }}
          onClick={() => deleteShow()}
        >
          här{' '}
        </Typography>
      </RouterLink>
    </div>
  )

  const renderNoSeasonView = () => {
    return <PlaceholderDisplay component={textComponent} />
  }

  const handleClick = () => {
    setViewEpisodes(false)
  }

  const saveShowInfo = () => {
    let allFieldsFilled = true

    Object.values(SETTINGS.SHOW).forEach(({ content }) => {
      getRef(content.eng)?.current?.reportValidity()
      if (getRef(content.eng)?.current?.reportValidity() === false) {
        allFieldsFilled = false
      }
    })
    if (allFieldsFilled) {
      body.thumbnail && (body.thumbnail = [body.thumbnail])
      sendRequest(
        `${endpoints.shows}/${id}/`,
        'PUT',
        JSON.stringify(body),
        'action'
      )
    }
  }

  const changeSeason = (input) => {
    setValue(input)
  }

  const displayAddSeason = () => {
    return show?.waterfall ? (seasons?.length < 1 ? true : false) : true
  }

  const renderSeasonTabs = () => {
    return (
      <Paper className={classes.paper}>
        {renderAlert()}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {renderTabsFromSeason()}
        </Tabs>
        {viewEpisodes ? (
          !!seasons.length && (
            <EpisodeList
              showId={id}
              showIsPublished={show?.isPublished}
              season={seasons[value].season}
              showIsWaterfall={show?.waterfall}
              episodeIds={seasons[value].episodes}
              deleteSeason={deleteSeason}
              deleteEpisode={deleteEpisode}
            />
          )
        ) : (
          <Container
            style={{ backgroundColor: theme.palette.background.paper }}
          >
            <AddEpisodeView
              changeSeason={changeSeason}
              seasons={seasons.length}
              parentState={[value, setValue]}
              postEpisode={postEpisode}
            />
          </Container>
        )}
      </Paper>
    )
  }

  const renderAddseasonButton = () => {
    return (
      displayAddSeason() && (
        <Button
          className={classes.episodeButton}
          onClick={addSeason}
          style={{ color: theme.palette.primary.main }}
        >
          Lägg till säsong
        </Button>
      )
    )
  }

  return (
    <Page className={classes.root} title="News">
      <Container className={classes.mainContainer}>
        <Container
          className={classes.showContainer}
          style={{ padding: '20px 0 0 0' }}
        >
          {show && renderShowInfo()}
          <Container
            className={classes.inputContainer}
            style={{ padding: '1' }}
          >
            {renderContent(SETTINGS.SHOW)}
          </Container>
        </Container>
        <div className={classes.buttonContainer}>
          <Modal
            content={
              <ConfirmModalContent
                title={'Är du säker?'}
                action={() => publishItem(show._id)}
              />
            }
            button={
              <CustomButton
                size={'small'}
                color={
                  show?.isPublished
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary
                }
                backgroundColor={
                  show?.isPublished ? theme.palette.background.paper : null
                }
                isDisabled={show?.isPublished}
                text={show?.isPublished ? 'PUBLICERAD' : 'PUBLICERA'}
              />
            }
          />
          <Typography
            gutterBottom
            variant="body2"
            component="h6"
            style={{
              paddingLeft: '6px',
              paddingRight: '6px',
              fontSize: '15px',
              fontWeight: '200',
              color: theme.palette.primary.main
            }}
          >
            Prenumerationer: {show?.subscriptions}
          </Typography>
          {isContent && (
            <Button
              className={classes.episodeButton}
              onClick={handleClick}
              style={{ color: theme.palette.primary.main }}
            >
              Lägg till avsnitt
            </Button>
          )}

          {renderAddseasonButton()}

          {body !== null && (
            <Button
              style={{ float: 'right', color: theme.palette.primary.main }}
              className={classes.episodeButton}
              onClick={saveShowInfo}
            >
              Spara ändring
            </Button>
          )}
        </div>
        {isContent ? renderSeasonTabs() : renderNoSeasonView()}
      </Container>
    </Page>
  )
}
