import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  inputLabel: {
    color: theme.palette.primary.main
  }
}))

export default function CheckboxLabels({ label, parentHook, parentData }) {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(false)
  const [parentState, setParentState] = parentHook

  const setParenStateRef = React.useRef(() => {})

  setParenStateRef.current = () => {
    if (parentData) {
      setChecked(parentData[label.eng])
    }
  }

  React.useEffect(() => {
    setParenStateRef.current()
  }, [parentData])

  const handleChange = (event) => {
    setChecked(event.target.checked)
    setParentState({
      ...parentState,
      [label.eng]: event.target.checked
    })
  }
  return (
    <form className={classes.container}>
      <FormControlLabel
        style={{ padding: 0 }}
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={
          <Typography className={classes.inputLabel}>{label.swe}</Typography>
        }
      />
    </form>
  )
}
