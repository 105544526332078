import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.light
  }
}))

export default function LinearLoader() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          barColorPrimary: classes.barColorPrimary
        }}
      />
    </div>
  )
}
