import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, TextField, Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginTop: theme.spacing(2),
    width: '100%',
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  }
}))

export default function SecondStepAd({ setBody, isDisabled }) {
  const classes = useStyles()
  const [text, setText] = useState({ image: '', link: '' })
  const setBodyRef = useRef(() => {})

  setBodyRef.current = () => {
    setBody((prevState) => ({
      ...prevState,
      isAd: true
    }))
  }

  useEffect(() => {
    isDisabled(!(text['image'] !== '' && text['link'] !== ''))
  }, [text, isDisabled])

  useEffect(() => {
    setBodyRef.current()
  }, [])

  const handleChange = (value, selector) => {
    setText({ ...text, [selector]: value })
    setBody((prevState) => ({ ...prevState, [selector]: value }))
  }
  const renderInputs = () => {
    return (
      <form className={classes.root}>
        <Container className={classes.textField}>
          <TextField
            required
            fullWidth
            id="standard-required-1"
            label="Bild"
            value={text['image']}
            onChange={(e) => handleChange(e.target.value, 'image')}
          />
          <TextField
            required
            fullWidth
            value={text['link']}
            id="standard-required-2"
            label="Länk"
            onChange={(e) => handleChange(e.target.value, 'link')}
          />
        </Container>
      </form>
    )
  }

  return <>{renderInputs()}</>
}
