import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'

export default function HorizontalBarChart({ alternatives, votes }) {
  const colors = {
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)'
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ]
  }

  const retrunBackgroundColor = (amount) => {
    return colors.backgroundColor.slice(0, amount)
  }
  const retrunBorderColor = (amount) => {
    return colors.borderColor.slice(0, amount)
  }

  const data = {
    labels: alternatives,
    datasets: [
      {
        label: '# of Votes',
        data: votes,
        backgroundColor: retrunBackgroundColor(alternatives.length),
        borderColor: retrunBorderColor(alternatives.length),
        borderWidth: 1
      }
    ]
  }

  const options = {
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }
  return <HorizontalBar data={data} options={options} />
}
