import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CloseIcon from '@material-ui/icons/Close'
import theme from '../../theme/theme'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    alert: {
      padding: 0
    },
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function TransitionAlerts({ error, message, clear }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [timer, setTimer] = useState()
  const timerRef = useRef(() => {})

  timerRef.current = () => {
    clearTimeout(timer)
  }

  useEffect(() => {
    setOpen(true)
    closeAlert(7000)
    return () => timerRef.current()
  }, [error, message])

  const closeAlert = (time) => {
    let timeout = setTimeout(() => {
      setOpen(false)
    }, time)
    setTimer(timeout)
  }

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          severity={error ? 'error' : 'success'}
          style={{
            paddingTop: '0',
            paddingBottom: '0',
            backgroundColor: theme.palette.background.whitepaper
          }}
          action={
            <IconButton
              aria-label="close"
              color={error ? 'inherit' : 'secondary'}
              size="small"
              onClick={() => {
                setOpen(false)
                timerRef.current()
                clear()
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  )
}
