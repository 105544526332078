import { settings } from '../../src/settings.json'
export const endpoints = {
  sneakers: `${settings.URI}sneakers`,
  sneakers_admin: `${settings.URI}admin/sneakers`,
  news: `${settings.URI}news`,
  news_admin: `${settings.URI}admin/news`,
  events: `${settings.URI}events`,
  events_admin: `${settings.URI}admin/events`,
  shows: `${settings.URI}shows`,
  shows_admin: `${settings.URI}admin/shows`,
  auth: `${settings.URI}authentication`,
  tags: `${settings.URI}search`,
  administration: `${settings.URI}administration`,
  home: `${settings.URI}home`
}
