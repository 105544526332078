import React, { useEffect, useState, useRef, createRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { InputAdornment } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PlusIcon from '../reusable/plusIcon'
import CustomChip from '../reusable/CustomChip'
import CustomButton from '../buttons/CustomButton'
import theme from '../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%'
  },
  textArea: {
    width: '100%'
  },
  textField: {
    width: '100%'
  },
  margin: {
    margin: theme.spacing(1)
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.2)
    }
  },
  multilineColor: {
    color: theme.palette.primary.main
  }
}))

export default function Poll({
  label,
  helper,
  placeholder,
  parentHook,
  parentData,
  required,
  formRef,
  variant
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [titleText, setTitleText] = useState('')
  const [alternativText, setAlternativText] = useState('')
  const [chipsData, setChipsData] = useState([])
  const [localHelper, setLocalHelper] = useState()
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [click, setClick] = useState(true)

  const [localHelperAlternativ, setLocalHelperAlternativ] = useState()

  const elRefs = useRef([])

  const setParentStatRef = useRef(() => {})

  setParentStatRef.current = () => {
    if (open) {
      setParentState({
        ...parentState,
        [label.main.eng]: {
          ...parentState[label.main.eng],
          alternatives: chipsData,
          title: titleText
        }
      })
    }
  }

  useEffect(() => {
    setParentStatRef.current()
  }, [click, titleText, chipsData])

  useEffect(() => {
    setLocalHelper(helper.main.text)
    setLocalHelperAlternativ(helper.secondary.text)
  }, [helper])

  useEffect(() => {
    return () => {
      setError(false)
    }
  }, [])

  useEffect(() => {
    if (alternativText === '') {
      setError(false)
      setLocalHelperAlternativ(helper.secondary.text)
    }
  }, [alternativText, setLocalHelperAlternativ, helper.secondary.text])

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  useEffect(() => {
    if (!open) {
      setParentState({
        ...parentState,
        [label.main.eng]: null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleChangeText = (event) => {
    setTitleText(event.target.value)
  }
  const handleChangeAltenative = (event) => {
    setAlternativText(event.target.value)
  }

  const renderChips = () => {
    return chipsData?.map((data, i) => {
      return (
        <div key={data.text} ref={elRefs.current[i]}>
          <CustomChip
            key={data.text}
            label={data.text}
            handleDelete={() => handleDelete(data.text)}
          />
        </div>
      )
    })
  }

  const setCurrentRefs = () => {
    elRefs.current = [...elRefs.current, createRef()]
  }

  useEffect(() => {
    elRefs?.current[chipsData.length - 1]?.current?.scrollIntoView({
      behavior: 'smooth'
    })
  }, [chipsData])

  const handleDelete = (chipToDelete) => () => {
    setChipsData((currentChips) =>
      currentChips.filter((data) => data.text !== chipToDelete)
    )
  }

  const checkIfValueExistInState = (item) => {
    return chipsData.filter((data) => data.text === item.text).length === 0
  }

  const addChip = (src) => {
    if (!checkIfValueExistInState(src)) {
      setError(true)
      setLocalHelperAlternativ(helper.secondary.error)
    } else if (src.text === '') {
      setError(true)
      setLocalHelperAlternativ('Alternativet måste innehålla något')
    } else {
      setCurrentRefs()
      setChipsData((prevData) => [...prevData, src])
      setClick((prevState) => !prevState)
      setAlternativText('')
    }
  }

  useEffect(() => {
    if (parentData !== undefined) {
      if (
        parentData[label.main.eng] !== undefined &&
        parentData[label.main.eng] !== null
      ) {
        setTitleText(parentData[label.main.eng]?.title)
        if (Object.keys(parentData[label.main.eng].alternatives).length > 0) {
          setOpen(true)
          parentData[label.main.eng]?.alternatives.forEach((alt, i) => {
            setChipsData((prevData) => [...prevData, alt])
          })
        }
      }
    }
  }, [parentData, label.main.eng])

  const renderContent = () => {
    return (
      <div className={classes.root}>
        <FormControl
          ref={formRef}
          className={clsx(classes.margin, classes.root)}
          variant={variant}
        >
          <InputLabel
            htmlFor="outlined-adornment-password"
            style={{ color: theme.palette.primary.main }}
          >
            {label.main.swe}
          </InputLabel>
          <OutlinedInput
            required={required}
            placeholder={placeholder}
            fullWidth
            value={titleText}
            label={label.main.swe}
            style={{ paddingRight: 0 }}
            className={classes.textField}
            onChange={handleChangeText}
            // labelWidth={70}
            inputProps={{
              className: classes.multilineColor,
              style: {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset `
              }
            }}
          />
          <FormHelperText>{localHelper}</FormHelperText>
        </FormControl>
        <FormControl
          className={clsx(classes.margin, classes.root)}
          variant={variant}
        >
          <InputLabel
            htmlFor="outlined-adornment-password"
            style={{ color: theme.palette.primary.main }}
          >
            {label.secondary.swe}
          </InputLabel>
          <OutlinedInput
            error={error}
            placeholder={placeholder}
            fullWidth
            value={alternativText}
            label={label.secondary.swe}
            style={{ paddingRight: 0 }}
            className={classes.textField}
            onChange={handleChangeAltenative}
            inputProps={{
              className: classes.multilineColor,
              style: {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset `
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={{ color: theme.palette.primary.main }}
                  onClick={() => addChip({ text: alternativText, votes: [] })}
                >
                  <PlusIcon size={25} />
                </IconButton>
              </InputAdornment>
            }
            // labelWidth={70}
          />
          <FormHelperText>{localHelperAlternativ}</FormHelperText>
        </FormControl>
        <div className={classes.chipContainer}>{renderChips()}</div>
      </div>
    )
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <CustomButton
        action={handleClick}
        text={'Lägg till poll'}
        color={theme.palette.primary.main}
      />
      {open ? renderContent() : <></>}
    </form>
  )
}
