import React, { useEffect, useState, useRef } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import SuccessIcon from '../../../components/Icons/SuccessIcon'
import FailureIcon from '../../../components/Icons/FailureIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(3)
  }
}))

export default function FinalStep({ error, resetView }) {
  const classes = useStyles()
  const [timer, setTimer] = useState()

  const closeAlertRef = useRef(() => {})
  const timerRef = useRef(() => {})

  closeAlertRef.current = (time) => {
    let timeout = setTimeout(() => {
      resetView()
    }, time)
    setTimer(timeout)
  }

  timerRef.current = () => {
    clearTimeout(timer)
  }
  useEffect(() => {
    closeAlertRef.current(1000)

    return () => timerRef.current()
  }, [resetView])

  return (
    <Container className={classes.root}>
      {error ? <FailureIcon /> : <SuccessIcon />}
    </Container>
  )
}
