import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { firestore } from 'src/utils/firebase'

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
    color: 'white'
  },
  commentText: {
    color: 'white',
    fontSize: '1.2rem'
  },
  time: {
    fontSize: '0.8rem',
    paddingTop: '1px'
  },
  nameAndDeleteContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.primary.main
  },
  delete: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  showHideSubecommentsText: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
}))

export default function Comment({ comment, objectId, updateComments }) {
  const classes = useStyles()
  const [showSubComments, setShowSubComments] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const deleteComment = async (id) => {
    try {
      setIsLoading(true)
      await firestore
        .collection('comments')
        .doc(objectId)
        .collection('comments')
        .doc(id)
        .delete()
        .then((r) => {
          updateComments().then((res) => {
            setIsLoading(false)
          })
        })
    } catch (e) {
      setIsLoading(false)
    }
  }

  const deleteSubComment = async (id, parentId) => {
    try {
      setIsLoading(true)
      await firestore
        .collection('comments')
        .doc(objectId)
        .collection('comments')
        .doc(parentId)
        .collection('comments')
        .doc(id)
        .delete()
        .then((r) => {
          updateComments().then((res) => {
            setIsLoading(false)
          })
        })
    } catch (e) {
      setIsLoading(false)
    }
  }

  const nameAndDeleteContainer = (name) => {
    return (
      <div className={classes.nameAndDeleteContainer}>
        <p>{name}</p>
        {isLoading ? (
          <p>Loading..</p>
        ) : (
          <p
            className={classes.delete}
            onClick={() => deleteComment(comment.id)}
          >
            RADERA
          </p>
        )}
      </div>
    )
  }

  const subNameAndDeleteContainer = (id, name) => {
    return (
      <div className={classes.nameAndDeleteContainer}>
        <p>{name}</p>
        {isLoading ? (
          <p>Loading..</p>
        ) : (
          <p
            className={classes.delete}
            onClick={() => deleteSubComment(id, comment.id)}
          >
            RADERA
          </p>
        )}
      </div>
    )
  }

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={comment.name} src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary={nameAndDeleteContainer(comment.name)}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                // variant="body2"
                className={classes.inline}
              >
                <p className={classes.time}>
                  {new Date(
                    comment.createdAt.seconds * 1000
                  ).toLocaleDateString('sv-SE')}{' '}
                  {new Date(
                    comment.createdAt.seconds * 1000
                  ).toLocaleTimeString()}
                </p>
              </Typography>

              <p className={classes.commentText}>{comment.comment}</p>
              {comment.comments.length && !showSubComments ? (
                <p
                  className={classes.showHideSubecommentsText}
                  onClick={() => setShowSubComments(!showSubComments)}
                >
                  Visa kommentarer
                </p>
              ) : null}
              {showSubComments && (
                <>
                  {comment.comments.map((c) => {
                    return (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt={c.name}
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={subNameAndDeleteContainer(c.id, c.name)}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  // variant="body2"
                                  className={classes.inline}
                                >
                                  <p className={classes.time}>
                                    {new Date(
                                      c.createdAt.seconds * 1000
                                    ).toLocaleDateString('sv-SE')}{' '}
                                    {new Date(
                                      c.createdAt.seconds * 1000
                                    ).toLocaleTimeString()}
                                  </p>
                                </Typography>
                                <p className={classes.commentText}>
                                  {c.comment}
                                </p>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />{' '}
                      </>
                    )
                  })}
                  <p
                    className={classes.showHideSubecommentsText}
                    onClick={() => setShowSubComments(!showSubComments)}
                  >
                    Göm kommentarer
                  </p>
                </>
              )}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  )
}
