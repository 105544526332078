import 'react-perfect-scrollbar/dist/css/styles.css'
import React from 'react'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import GlobalStyles from 'src/components/GlobalStyles'
import 'src/mixins/chartjs'
import theme from 'src/theme'
import { notLoggedInRoutes, loggedInRoutes } from 'src/routes'
import 'src/theme/styles.css'
import { getCookieProp } from '../src/utils/cookie'

//TODO: Gör så att applikationen inte krashar vid tappad token
const App = () => {
  const isLoggedIn = getCookieProp('token') ? loggedInRoutes : notLoggedInRoutes
  const routing = useRoutes(isLoggedIn)
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  )
}

export default App
