import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
// import { Button, Fragment } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& :focus': {
      outline: 'none'
    },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  button: {
    '& > *': {
      float: 'right'
    }
  },
  form: {
    width: '100%'
  }
}))

export default function TransitionsModal({ content, button }) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <div className={classes.button} onClick={handleOpen}>
        {button}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open} unmountOnExit>
          {content ? (
            <>
              {React.cloneElement(
                content,
                { setOpen: setOpen, open: open },
                content.props.content
              )}
            </>
          ) : null}
        </Fade>
      </Modal>
    </div>
  )
}
