import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import useHttp from '../../../hooks/http'
import { endpoints } from '../../../utils/API_endpoints'
import LinearLoader from 'src/components/LinearLoader'

import PlaceholderDisplay from '../../../components/reusable/PlaceholderDisplay'
import theme from '../../../theme/theme'
import Alert from '../../../components/reusable/Alert'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TablePagination,
  Toolbar,
  IconButton,
  Checkbox,
  Tooltip,
  Paper,
  Typography
} from '@material-ui/core'
import EpisodeCellItem from './EpisodeCellItem'

function descendingComparator(a, b, orderBy) {
  console.log(a[orderBy], b[orderBy])
  if (orderBy === 'publishedAt') {
    if (a[orderBy] === null) {
      return 1
    }
    if (b[orderBy] === null) {
      return -1
    }

    return new Date(b[orderBy]) - new Date(a[orderBy])
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = Object.values(array).map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  { id: 'arrow', numeric: false, disablePadding: true, label: 'Öppna' },
  {
    id: 'episode',
    numeric: false,
    disablePadding: true,
    label: 'Avsnitt'
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Titel' },
  {
    id: 'publishedAt',
    numeric: true,
    disablePadding: false,
    label: 'Publiserad'
  },
  { id: 'duration', numeric: true, disablePadding: false, label: 'Längd' },
  { id: 'likes', numeric: true, disablePadding: false, label: 'Gillningar' },
  { id: 'views', numeric: true, disablePadding: false, label: 'Visningar' }
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: theme.palette.background.paper }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell, i) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                disabled={i === 0}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && i !== 0 ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    opacity: '0.9'
  },

  title: {
    flex: '1 1 100%'
  }
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected, deleteEpisode } = props

  const handleClickDeleteEpisode = () => {
    deleteEpisode()
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} valda
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Alla avsnitt
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={handleClickDeleteEpisode}>
            <DeleteIcon style={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Tooltip>
      ) : (
        <div />
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 100
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  label: {
    marginTop: theme.spacing(1)
  },
  deleteSeasonButton: {
    '&:hover': {
      textDecoration: 'underline'
    },
    color: theme.palette.background.paper
  },
  selectDropdown: { color: theme.palette.primary.main },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.paper,
      opacity: '0.9',
      '& > .MuiTableCell-root': {
        color: theme.palette.primary.main
      }
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
}))

export default function EpisodeList({
  showId,
  showIsPublished,
  season,
  episodeIds,
  deleteSeason,
  deleteEpisode,
  showIsWaterfall
}) {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('episode')
  const [opened, setOpened] = useState([])
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [episodes, setEpisodes] = useState([])
  const [body, setBody] = useState({})
  const [inputText, setInputText] = useState('')
  const [tagsData, setTagsData] = useState(null)
  const [tags, setTags] = useState({})
  const {
    isLoading,
    error,
    data,
    statusCode,
    sendRequest,
    reqExtra,
    clear
  } = useHttp()

  const setTagsDataRef = useRef(() => {})
  const getEpisodeTagsRef = useRef(() => {})
  const clearRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const getEpisodeRef = useRef(() => {})
  const getEpisodesRef = useRef(() => {})
  const clearEpsiodeRef = useRef(() => {})

  setTagsDataRef.current = () => {
    setTagsData((prevState) => ({
      ...prevState,
      [data[0].relationId]: data[0]
    }))
  }

  getEpisodeTagsRef.current = () => {
    episodeIds.forEach((id) =>
      sendRequest(`${endpoints.tags}/${id}`, 'GET', null, 'tags')
    )
  }

  clearRef.current = () => {
    clear()
  }
  setResponseAlertDataRef.current = (data) => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }

  getEpisodeRef.current = () => {
    episodeIds.forEach((episode) => {
      sendRequest(
        `${endpoints.shows_admin}/${showId}/season/${season}/episode/${episode}`,
        'GET',
        null,
        'get'
      )
    })
  }

  getEpisodesRef.current = (data) => {
    let tmpArray = Object.values(episodes)
    tmpArray.push(data[0])
    setInputText((prevState) => ({
      ...prevState,
      [data[0]._id]: {
        ...prevState[data[0]._id],
        title: data[0].title,
        description: data[0].description
      }
    }))
    setEpisodes(Object.assign({}, tmpArray))
  }

  clearEpsiodeRef.current = () => {
    setEpisodes([])
  }

  const [responseAlertData, setResponseAlertData] = useState({})
  useEffect(() => {
    if (!isLoading && !error && data && reqExtra === 'get') {
      if (data.length > 0) {
        getEpisodesRef.current(data)
      }
    }
    if (!isLoading && !error && statusCode && data && reqExtra === 'action') {
      setResponseAlertDataRef.current(data)
      getEpisodeTagsRef.current()
    }

    if (!isLoading && !error && data && reqExtra === 'tags') {
      setResponseAlertDataRef.current(data)
      if (data.length > 0) {
        setTagsDataRef.current(data)
      }
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    clearEpsiodeRef.current()
  }, [season])

  useEffect(() => {
    clearEpsiodeRef.current()

    getEpisodeRef.current()

    return () => clearRef.current()
  }, [season, showId, episodeIds])

  useEffect(() => {
    getEpisodeTagsRef.current()

    return () => clearRef.current()
  }, [])

  const putEpisodeTags = (episodeId) => {
    sendRequest(
      `${endpoints.tags}/${episodeId}`,
      'PUT',
      JSON.stringify(tags),
      'action'
    )
  }
  const toggleComments = (episodeId, commentsDisabledState) => {
    sendRequest(
      `${endpoints.shows}/${showId}/season/${season}/episode/${episodeId}/toggleComments`,
      'PUT',
      JSON.stringify({ commentsDisabled: !commentsDisabledState }),
      'put'
    )
  }

  const publishItem = (episodeId) => {
    sendRequest(
      `${endpoints.shows}/${showId}/season/${season}/episode/${episodeId}/publish`,
      'PUT',
      null,
      'publish'
    )
  }

  const reorderSeason = (seasons) => {
    sendRequest(
      `${endpoints.shows_admin}/${showId}/season/${season}`,
      'PUT',
      JSON.stringify(seasons),
      'put'
    )
  }

  const clearEpisodeTagState = () => {
    setTags({})
  }

  const clearResponse = () => {
    setResponseAlertData({})
  }

  const handleClickUpdate = (episodeId) => {
    if (Object.keys(body).length > 0) {
      sendRequest(
        `${endpoints.shows}/${showId}/season/${season}/episode/${episodeId}`,
        'PUT',
        JSON.stringify(body),
        'action'
      )
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Object.values(episodes).map((n) => {
        return { name: n._id, IndexOfName: n.episode }
      })
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name, indexOfName) => {
    let tmp = { name, indexOfName }
    const selectedIndex = findIndex(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, tmp)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeEdit = (event, id, select) => {
    const value = event.target.value
    setInputText((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [select]: value
      }
    }))
    setBody({ ...body, [select]: value })
  }

  const handleDeleteSeason = () => {
    deleteSeason(season)
  }

  const renderAlert = () => {
    return (
      <>
        {responseAlertData.modal && (
          <Alert
            message={responseAlertData.message}
            error={responseAlertData.error}
            clear={clearResponse}
          />
        )}
      </>
    )
  }

  const textComponent = (
    <div className={classes.label}>
      <Typography>
        Det finns inga avsnitt, lägg till ett avsnitt eller ta bort säsongen
        genom att klicka
      </Typography>
      <Typography
        className={classes.deleteSeasonButton}
        style={{ cursor: 'pointer' }}
        onClick={() => handleDeleteSeason()}
      >
        här
      </Typography>
    </div>
  )

  const renderNoEpisodeView = () => {
    return <PlaceholderDisplay component={textComponent} />
  }

  const handleOpened = (id) => {
    if (opened.includes(id)) {
      setOpened((prevState) => prevState.filter((ids) => ids !== id))
    } else {
      setOpened((prevState) => [...prevState, id])
    }
  }

  const findIndex = (name) => selected.findIndex((obj) => obj.name === name)
  const isOpened = (id) => opened.indexOf(id) !== -1
  const isSelected = (name) => findIndex(name) !== -1

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, Object.keys(episodes) - page * rowsPerPage)

  const _deleteEpisode = () => {
    deleteEpisode(season, selected)
    setSelected([])
  }

  const findIndexByValue = (key, value) => {
    return Object.values(episodes).findIndex((episode) => {
      return episode[key] === value
    })
  }

  const updateEpisodeOrder = (value, id) => {
    sendRequest(
      `${endpoints.shows}/${showId}/season/${season}/episode/${id}`,
      'PUT',
      JSON.stringify({ episode: value }),
      'episode'
    )
  }

  const handleEpisodeChange = (event, episode) => {
    const targetIndex = findIndexByValue('episode', event.target.value)
    const sourceIndex = findIndexByValue('episode', episode)
    if (targetIndex === sourceIndex) {
      return
    }
    const newEpisodeOrderKeyAndValuesArray = [
      { id: episodes[sourceIndex]._id, value: event.target.value },
      { id: episodes[targetIndex]._id, value: episode }
    ]

    let copy = { ...episodes }
    copy[sourceIndex] = {
      ...copy[sourceIndex],
      episode: event.target.value
    }
    copy[targetIndex] = {
      ...copy[targetIndex],
      episode: episode
    }

    setEpisodes(copy)

    reorderSeason(
      Object.values(copy)
        .sort((a, b) =>
          showIsWaterfall ? b.episode - a.episode : a.episode - b.episode
        )
        .map(({ _id }) => _id)
    )
    // setEpisodes((prevState) => ({
    //   ...prevState,
    //   [sourceIndex]: {
    //     ...prevState[sourceIndex],
    //     episode: event.target.value
    //   },
    //   [targetIndex]: {
    //     ...prevState[targetIndex],
    //     episode: episode
    //   }
    // }))

    newEpisodeOrderKeyAndValuesArray.forEach((action) => {
      updateEpisodeOrder(action.value, action.id)
    })
  }

  const formatDuration = (duration) => {
    let slicedStr = duration.slice(2, duration.length)
    return slicedStr.toLowerCase()
  }

  //TODO: ändra värdet på tablen så att sortering ska fungera
  //TODO: bara visa response medelandet endast vid rutan som blev redigerad,
  return (
    <div className={classes.root}>
      {isLoading && <LinearLoader />}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          deleteEpisode={() => _deleteEpisode(season, selected)}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={Object.keys(episodes).length}
            />
            <TableBody>
              {stableSort(episodes, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((episode, index) => {
                  const isItemSelected = isSelected(episode._id)
                  const isOpen = isOpened(episode._id)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <EpisodeCellItem
                      key={episode._id}
                      episode={episode}
                      episodes={episodes}
                      index={index}
                      isItemSelected={isItemSelected}
                      isOpen={isOpen}
                      labelId={labelId}
                      handleClick={handleClick}
                      handleOpened={handleOpened}
                      formatDuration={formatDuration}
                      renderAlert={renderAlert}
                      handleClickUpdate={handleClickUpdate}
                      handleChangeEdit={handleChangeEdit}
                      handleEpisodeChange={handleEpisodeChange}
                      toggleComments={toggleComments}
                      tagsData={tagsData}
                      inputText={inputText}
                      parentHook={[tags, setTags]}
                      clearEpisodeTagState={clearEpisodeTagState}
                      putEpisodeTags={putEpisodeTags}
                      publishItem={publishItem}
                      showIsPublished={showIsPublished}
                    />
                  )
                })}
            </TableBody>
            <TableBody>
              {emptyRows > 0 && Object.keys(episodes).length === 0 ? (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7}>
                    {!isLoading && renderNoEpisodeView()}
                  </TableCell>
                </TableRow>
              ) : (
                emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}></TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          SelectProps={{
            MenuProps: { classes: { paper: classes.selectDropdown } }
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(episodes).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
