import React from 'react'
import TextInput from './inputs/text'
import DateInput from './inputs/date'
import DateInputPartial from './inputs/date-partial'
import TextMultiple from './inputs/textMultiple'
import TextArea from './inputs/textArea'
import Poll from './inputs/poll'
import Ad from './inputs/ad'
import CheckboxLabels from './inputs/checkbox'

export const TYPES = {
  TEXT: 'TEXT',
  DATE: 'DATE',
  DATE_PARTIAL: 'DATE_PARTIAL',
  TEXT_MULTIPLE: 'TEXT_MULTIPLE',
  TEXT_AREA: 'TEXT_AREA',
  POLL: 'POLL',
  AD: 'AD',
  CHECKBOX: 'CHECKBOX'
}

export const TYPE_CONTENT = [
  {
    type: TYPES.TEXT,
    component: (input, parentHook, parentData, formRef) => (
      <TextInput
        formRef={formRef}
        input={input}
        key={input.id}
        id={input.id}
        label={input.content}
        variant={input.variant}
        helper={input.helperText}
        placeholder={input.placeholder}
        parentHook={parentHook}
        parentData={parentData}
        required={input.required}
      />
    )
  },
  {
    type: TYPES.TEXT_MULTIPLE,
    component: (input, parentHook, parentData, formRef) => (
      <TextMultiple
        formRef={formRef}
        key={input.id}
        id={input.id}
        variant={input.variant}
        label={input.content}
        helper={input.helperText}
        placeholder={input.placeholder}
        parentHook={parentHook}
        parentData={parentData}
        required={input.required}
      />
    )
  },
  {
    type: TYPES.TEXT_AREA,
    component: (input, parentHook, parentData, formRef) => (
      <TextArea
        formRef={formRef}
        key={input.id}
        id={input.id}
        variant={input.variant}
        label={input.content}
        helper={input.helperText}
        placeholder={input.placeholder}
        parentHook={parentHook}
        parentData={parentData}
        maxRows={input.maxRows}
        required={input.required}
      />
    )
  },
  {
    type: TYPES.DATE,
    component: (input, parentHook, parentData, formRef) => (
      <DateInput
        formRef={formRef}
        key={input.id}
        id={input.id}
        field={input.content}
        label={input.content}
        parentHook={parentHook}
        parentData={parentData}
        helper={input.helperText}
        required={input.required}
        variant={input.variant}
      />
    )
  },
  {
    type: TYPES.POLL,
    component: (input, parentHook, parentData, formRef) => (
      <Poll
        formRef={formRef}
        key={input.id}
        id={input.id}
        parentHook={parentHook}
        parentData={parentData}
        helper={input.helperText}
        label={input.content}
        variant={input.variant}
        required={input.required}
      />
    )
  },
  {
    type: TYPES.AD,
    component: (input, parentHook, parentData, formRef) => (
      <Ad
        formRef={formRef}
        key={input.id}
        id={input.id}
        parentHook={parentHook}
        parentData={parentData}
        helper={input.helperText}
        label={input.content}
        required={input.required}
        variant={input.variant}
      />
    )
  },
  {
    type: TYPES.CHECKBOX,
    component: (input, parentHook, parentData, formRef) => (
      <CheckboxLabels
        key={input.id}
        parentData={parentData}
        parentHook={parentHook}
        label={input.content}
      />
    )
  },
  {
    type: TYPES.DATE_PARTIAL,
    component: (input, parentHook, parentData, formRef) => (
      <DateInputPartial
        formRef={formRef}
        key={input.id}
        id={input.id}
        field={input.content}
        label={input.content}
        parentHook={parentHook}
        parentData={parentData}
        helper={input.helperText}
        required={input.required}
        variant={input.variant}
      />
    )
  }
]
