import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import theme from '../../theme/theme'
import { useDynamicRefs } from '../../hooks/useDynamicRefs'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  multilineColor: {
    color: theme.palette.primary.main
  }
}))

export default function TextArea({
  id,
  label,
  helper,
  placeholder,
  parentHook,
  parentData,
  variant,
  maxRows,
  required,
  formRef
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [text, setText] = useState('')
  const [localHelper, setLocalHelper] = useState()
  const [, setRef] = useDynamicRefs()
  const handleChange = (event) => {
    setText(event.target.value)
    setParentState({ ...parentState, [label.eng]: event.target.value })
  }

  useEffect(() => {
    setLocalHelper(helper?.text)
  }, [helper])

  useEffect(() => {
    if (parentData) setText(parentData[label.eng])
  }, [parentData, label.eng])

  return (
    <form ref={setRef(label.eng)} className={classes.root} autoComplete="off">
      <TextField
        id={id}
        key={id}
        required={required}
        label={label?.swe}
        fullWidth
        margin="normal"
        placeholder={placeholder}
        multiline
        className={classes.textField}
        helperText={localHelper}
        value={text}
        rowsMax={maxRows}
        onChange={handleChange}
        variant={variant}
        inputProps={{
          className: classes.multilineColor
        }}
        InputLabelProps={{
          style: { color: theme.palette.primary.main }
        }}
      />
    </form>
  )
}
