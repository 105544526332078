import { useState, useEffect } from 'react'

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    isMobile: false,
    smallTabletWidth: false,
    isTablet: false,
    isDesktop: false,
    width: undefined,
    height: undefined
  })

  const mobileWidth = 450
  const smallTabletWidth = 767
  const tabletWidth = 1024

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: width <= mobileWidth,
        smallTabletWidth: width <= smallTabletWidth,
        isTablet: width <= tabletWidth,
        isDesktop: width > tabletWidth
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
