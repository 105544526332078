import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import HorizontalBarChart from '../../mixins/HorizontalBarChart'
import theme from '../../theme/theme'
const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium
  },
  paper: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    boxShadow: theme.shadows[5],
    padding: theme.spacing(10, 5, 10),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  buttonPrimary: {
    minWidth: '100px',
    backgroundColor: 'blue'
  },
  buttonSecondary: {
    minWidth: '100px',
    backgroundColor: 'Red'
  }
}))

export default function ConfirmContent({ parentData, setOpen }) {
  const classes = useStyles()

  const calculateVotes = (alternatives) => {
    return alternatives.map(({ votes }) => {
      return votes.length
    })
  }

  const getAlternatives = (alternatives) => {
    return alternatives.map(({ text }) => {
      return text
    })
  }

  return (
    <div className={classes.modalContainer}>
      <Container
        className={classes.chartContainer}
        styles={{
          display: 'flex',
          flexDirection: 'center',
          textAlign: 'center'
        }}
      >
        <Typography
          gutterBottom
          variant="subtitle2"
          component="h2"
          style={{
            fontSize: '20px',
            fontWeight: '400',
            textAlign: 'center',
            color: theme.palette.primary.main
          }}
        >
          {parentData.poll.title ? parentData.poll.title : ''}
        </Typography>

        <HorizontalBarChart
          alternatives={getAlternatives(parentData.poll.alternatives)}
          votes={calculateVotes(parentData.poll.alternatives)}
        />
      </Container>
    </div>
  )
}
