import React, { useEffect, useState, useRef } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Typography,
  Container
} from '@material-ui/core'
import Check from '@material-ui/icons/Check'

import FirstStep from './FirstStep'
import SecondStepPrimary from './SecondStepPrimary'
import SecondStepAd from './SecondStepAd'
import SecondStepCustom from './SecondStepCustom'
import ThirdStepPrimary from './ThirdStepPrimary'
import FinalStep from './FinalStep'
import useHttp from '../../../hooks/http'
import { endpoints } from '../../../utils/API_endpoints'

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main
    }
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main
    }
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))(StepConnector)

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.dark,
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: theme.palette.primary.main
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18
  }
}))

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(5)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& > *': {
      textAlign: 'center'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  actionGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1)
  }
}))

function getSteps() {
  return ['Välj typ', 'Välj produkt', 'Skapa']
}

const options = [
  {
    key: 'ad',
    component: (setBody, isDisabled) => (
      <SecondStepAd isDisabled={isDisabled} setBody={setBody} />
    )
  },
  {
    key: 'current',
    component: (setBody, isDisabled) => (
      <SecondStepPrimary isDisabled={isDisabled} setBody={setBody} />
    )
  },
  {
    key: 'custom',
    component: (setBody, isDisabled) => (
      <SecondStepCustom isDisabled={isDisabled} setBody={setBody} />
    )
  }
]

export default function CustomizedSteppers({
  parentHook,
  responseAlertData,
  setResponseAlertData
}) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [select, setSelect] = useState()
  const steps = getSteps()
  const [body, setBody] = parentHook
  const [disabled, isDisabled] = useState(true)
  const {
    isLoading,
    error,
    data,
    sendRequest,
    statusCode,
    reqExtra
  } = useHttp()

  const setBodyRef = useRef(() => {})
  const setResponseAlertDataRef = useRef(() => {})
  const sendRequestRef = useRef(() => {})
  sendRequestRef.current = () => {
    setBodyRef.current()
    sendRequest(`${endpoints.home}`, 'POST', JSON.stringify(body), 'post')
  }

  setResponseAlertDataRef.current = () => {
    setResponseAlertData({
      ...responseAlertData,
      message: data.message,
      modal: data.modal,
      error: data.error
    })
  }
  setBodyRef.current = () => {
    setBody({ ...body, isAd: false })
  }

  useEffect(() => {
    if (!isLoading && !error && statusCode && data && reqExtra === 'post') {
      setResponseAlertDataRef.current()
    }
  }, [data, error, isLoading, reqExtra, statusCode])

  useEffect(() => {
    if (activeStep === 3) {
      sendRequestRef.current()
    }
  }, [activeStep])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setBody({ isAd: false })
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const renderSecondStep = () => {
    return options
      .find((component) => select === component.key)
      .component(setBody, isDisabled)
  }

  const resetView = () => {
    setBody({})
    setActiveStep(0)
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FirstStep isDisabled={isDisabled} setSelect={setSelect} />
      case 1:
        return renderSecondStep()
      case 2:
        return <ThirdStepPrimary body={body} />
      case 3:
        return (
          <FinalStep error={responseAlertData?.error} resetView={resetView} />
        )
      default:
        return 'Något har gjort att komponenten inte har laddat, testa att ladda om websidan'
    }
  }

  const notCompletedActionGroup = () => {
    return (
      <React.Fragment>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          Tillbaka
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={disabled}
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? 'Skicka' : 'Nästa'}
        </Button>
      </React.Fragment>
    )
  }

  const completedActionGroup = () => (
    <React.Fragment>
      <Typography className={classes.instructions}>
        {responseAlertData?.message}
      </Typography>
    </React.Fragment>
  )

  return (
    <Container style={{ padding: 0 }} className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container
        style={{ padding: 0, height: ' 100%' }}
        className={classes.content}
      >
        <Container style={{ padding: 0, height: '100%' }}>
          {getStepContent(activeStep)}
        </Container>
        <Container className={classes.actionGroup}>
          {activeStep === steps.length
            ? completedActionGroup()
            : notCompletedActionGroup()}
        </Container>
      </Container>
    </Container>
  )
}
