import React from 'react'

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Container,
  makeStyles
} from '@material-ui/core/'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}))

export default function FirstStep({ setSelect, isDisabled }) {
  const classes = useStyles()

  const onChange = (e) => {
    isDisabled(!e.target.value)
    setSelect(e.target.value)
  }
  return (
    <Container className={classes.root}>
      <FormControl component="legend">
        <Typography component="legend" style={{ textAlign: 'center' }}>
          Vilken typ av artikel ska läggas till
        </Typography>

        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
        >
          <FormControlLabel
            value="ad"
            control={<Radio color="primary" />}
            label="Annons"
            labelPlacement="start"
            onChange={(e) => onChange(e)}
          />
          <FormControlLabel
            value="current"
            control={<Radio color="primary" />}
            label="Befintlig"
            labelPlacement="start"
            onChange={(e) => onChange(e)}
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="Custom"
            labelPlacement="start"
            onChange={(e) => onChange(e)}
          />
        </RadioGroup>
      </FormControl>
    </Container>
  )
}
