import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { makeStyles, Container, TextField } from '@material-ui/core'
import theme from '../../theme/theme'
import { useDynamicRefs } from 'src/hooks/useDynamicRefs'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import 'react-datepicker/dist/react-datepicker.css'
registerLocale('sv', sv)

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  wrapper: {
    width: '98%'
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.dark,
    margin: theme.spacing(1),
    width: '100%'
  },
  multilineColor: {
    color: theme.palette.primary.main
  },
  multilineColorLight: {
    color: theme.palette.primary.light
  },
  dateContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  radioButtonContianer: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioButtonLabel: {
    color: theme.palette.primary.main,
    fontSize: '12pz'
  }
}))

export default function DateInput({
  label,
  parentHook,
  parentData,
  helper,
  required,
  variant,
  startValue,
  dateTheme = 'dark'
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [localHelper, setLocalHelper] = useState()
  const [selectedDate, handleDateChange] = useState()

  const [, setRef] = useDynamicRefs()

  const setParenStateRef = useRef(() => {})

  setParenStateRef.current = () => {
    // if (!parentData) {
    setParentState({ ...parentState, [label.eng]: selectedDate })
    // }
  }

  useEffect(() => {
    if (startValue) {
      handleDateChange(startValue)
    }
  }, [startValue])

  useEffect(() => {
    setParenStateRef.current()
  }, [selectedDate])

  useEffect(() => {
    setLocalHelper(helper?.text)
  }, [helper])

  useEffect(() => {
    if (parentData) {
      handleDateChange(parentData[label.eng] || null)
    }
  }, [parentData, label.eng])

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, placeholder }, ref) => (
      <TextField
        key={label.swe}
        id={label.swe}
        required={required}
        placeholder={placeholder}
        value={value}
        margin="normal"
        label={label.swe}
        className={classes.textField}
        helperText={localHelper}
        onClick={onClick}
        variant={variant}
        inputProps={{
          className:
            dateTheme === 'light'
              ? classes.multilineColorLight
              : classes.multilineColor
        }}
        InputLabelProps={{
          style: {
            color:
              dateTheme === 'light'
                ? theme.palette.primary.dark
                : theme.palette.primary.main
          }
        }}
      />
    )
  )

  return (
    <Container className={classes.dateContainer}>
      <form ref={setRef(label.eng)} className={classes.container}>
        <DatePicker
          wrapperClassName={classes.wrapper}
          showTimeSelect
          className={classes.textField}
          locale="sv"
          minDate={new Date()}
          title={localHelper}
          selected={
            typeof selectedDate === 'string'
              ? new Date(selectedDate)
              : selectedDate
          }
          onChange={handleDateChange}
          timeIntervals={1}
          fixedHeight
          dateFormat="yyyy MMMM d, HH:mm"
          customInput={<ExampleCustomInput text={label.swe} />}
        />
      </form>
    </Container>
  )
}
