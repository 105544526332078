import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0)
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

export default function PlusIcon({ size }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Icon
        className={classes.icon}
        style={{
          fontSize: size,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        add_circle
      </Icon>
    </div>
  )
}
