import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import theme from '../../theme/theme'
import { useDynamicRefs } from '../../hooks/useDynamicRefs'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  multilineColor: {
    color: theme.palette.primary.main
  }
}))

export default function TextInput({
  id,
  input,
  label,
  helper,
  placeholder,
  parentHook,
  parentData,
  variant,
  required
}) {
  const classes = useStyles()
  const [parentState, setParentState] = parentHook
  const [text, setText] = useState('')
  const [localHelper, setLocalHelper] = useState()
  const [, setRef] = useDynamicRefs()

  const handleChange = (event) => {
    setText(event.target.value)
    setParentState({ ...parentState, [label.eng]: event.target.value })
  }

  useEffect(() => {
    if (helper) {
      setLocalHelper(helper.text)
    }
  }, [helper])

  useEffect(() => {
    if (parentData) setText(parentData[label.eng])
  }, [parentData, label.eng])

  return (
    <form ref={setRef(label.eng)} className={classes.root} autoComplete="off">
      <TextField
        key={id}
        id={id}
        required={required}
        placeholder={placeholder}
        fullWidth
        value={text}
        margin="normal"
        label={label.swe}
        className={classes.textField}
        helperText={localHelper}
        onChange={handleChange}
        variant={variant}
        inputProps={{
          className: classes.multilineColor
        }}
        InputLabelProps={{
          style: { color: theme.palette.primary.main }
        }}
      />
    </form>
  )
}
