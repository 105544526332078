import { TYPES } from '../components/inputTypes'
import { v4 as uuidv4 } from 'uuid'
export const SETTINGS = {
  SNEAKERS: {
    MAKE: {
      id: uuidv4(),
      required: true,
      variant: 'outlined',
      content: {
        swe: 'Märke',
        eng: 'make'
      },
      type: TYPES.TEXT,
      helperText: { text: 'Vilket märke skon har', error: '' },
      placeholder: 'e.g. Nike'
    },
    MODEL: {
      id: uuidv4(),
      required: true,
      variant: 'outlined',
      content: {
        swe: 'Modell',
        eng: 'model'
      },
      type: TYPES.TEXT,
      helperText: { text: 'Modellnamnet på skon', error: '' },
      placeholder: 'e.g. Air Max 1'
    },
    IMAGE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Bild', eng: 'image' },
      type: TYPES.TEXT_MULTIPLE,
      helperText: {
        text: 'Url till bilden, länk till att ladda upp bild',
        error: 'Bilden är redan tillagd!'
      },
      placeholder: 'http://',
      variant: 'outlined'
    },
    PRICE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Pris', eng: 'price' },
      helperText: { text: '', error: 'Priset måste vara en siffra!' },
      type: TYPES.TEXT,
      variant: 'outlined'
    },
    COLOR: {
      id: uuidv4(),
      required: false,
      content: {
        swe: 'Färg',
        eng: 'color'
      },
      helperText: { text: 'Vilken färg skon har', error: '' },
      type: TYPES.TEXT,
      placeholder: 'e.g. Röd',
      variant: 'outlined'
    },
    SIZE: {
      id: uuidv4(),
      required: false,
      variant: 'outlined',
      content: {
        swe: 'Storlek',
        eng: 'size'
      },
      type: TYPES.TEXT_MULTIPLE,
      helperText: {
        text: 'Vilka storlekar kommer skon att släppas i',
        error: ''
      },
      placeholder: 'e.g. 43'
    },
    DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Release datum', eng: 'release' },
      helperText: {
        text: 'Ställ in vilket datum skon kommer att släppas',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE_PARTIAL,
      variant: 'outlined'
    },
    RESELLSERS: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Återförsäljare', eng: 'reseller' },
      helperText: {
        text: 'Skriv in en url till återförsäljare',
        error: 'Återförsäljaren har redan blivit tillagd'
      },
      type: TYPES.TEXT_MULTIPLE,
      variant: 'outlined'
    },
    PUBLISH_DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Publiceras vid', eng: 'publishedAt' },
      helperText: {
        text: 'Ställ in vilket datum och tid skon kommer att publiceras',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE,
      variant: 'outlined'
    }
  },
  NEWS: {
    ORIGINALCONTENT: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Skapat av TH|RD', eng: 'isOriginal' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.CHECKBOX
    },
    AD: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Annonsdatum', eng: 'ad' },
      helperText: {
        text: 'Ställ in till vilket datum annonsen kommer att gälla',
        error: 'datumet har redan varit'
      },
      variant: 'outlined',
      type: TYPES.AD
    },

    TITLE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Titel', eng: 'title' },
      helperText: {
        text: 'Välj en title på nyheten',
        error: 'Nyheten måste ha en titel'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    AUTHOR: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Journalist', eng: 'author' },
      helperText: {
        text: 'Vem som har skrivit artikeln',
        error: 'Artikeln måste ha en journalist'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    INFORMATION: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Innehåll', eng: 'information' },
      helperText: {
        text: 'Beskrivning',
        error: 'Artikeln får inte vara tom'
      },
      placeholder: '...',
      variant: 'outlined',
      type: TYPES.TEXT_AREA,
      maxRows: 7
    },
    GENRE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Genre', eng: 'genre' },
      helperText: {
        text: 'Vilken genre är atikeln',
        error: 'Artikeln måste ha en genre'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    IMAGE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Bild', eng: 'image' },
      helperText: {
        text: 'Url till bilden, länk till att ladda upp bild',
        error: 'Bilden är redan tillagd!'
      },
      type: TYPES.TEXT_MULTIPLE,
      placeholder: 'http://',
      variant: 'outlined'
    },
    PUBLISH_DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Publiceras vid', eng: 'publishedAt' },
      helperText: {
        text: 'Ställ in vilket datum och tid nyheten kommer att publiceras',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE,
      variant: 'outlined'
    },
    POLL: {
      id: uuidv4(),
      required: false,
      content: {
        main: { swe: 'Poll', eng: 'poll' },
        secondary: { swe: 'Alternativ', eng: 'alternative' }
      },
      helperText: {
        main: {
          text: 'Lägg till titel på pollen',
          error: 'Poll måste ha en titel'
        },
        secondary: {
          text: 'Lägg till ett alternativ',
          error: 'Alternativet finns redan'
        }
      },
      type: TYPES.POLL,
      variant: 'outlined'
    }
  },
  EVENTS: {
    TYPE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Event typ', eng: 'type' },
      helperText: {
        text: 'Vad är det för typ av event?',
        error: 'Eventet måste ha en typ'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    TOURNAME: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Event namn', eng: 'tourName' },
      helperText: {
        text: 'Vart ska eventet vara?',
        error: 'Eventet måste ha en plats'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    ARTIST: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Artist', eng: 'artist' },
      helperText: {
        text: 'Vem är artisten på eventet?',
        error: 'Eventet måste ha en artist'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    LOCATION: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Stad', eng: 'location' },
      helperText: {
        text: 'Vilken stad ska eventet vara?',
        error: 'Eventet måste vara i en stad'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    VENUE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Plats', eng: 'venue' },
      helperText: {
        text: 'Vart ska eventet vara?',
        error: 'Eventet måste ha en plats'
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    INFO: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Information', eng: 'info' },
      helperText: {
        text: 'Information?',
        error: 'Eventet måste ha en information'
      },
      variant: 'outlined',
      type: TYPES.TEXT_AREA,
      maxRows: 7
    },
    EXTRA_INFO: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Extra information', eng: 'extraInfo' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.TEXT_AREA,
      maxRows: 7
    },
    TICKETS: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Billjetter', eng: 'tickets' },
      helperText: {
        text: 'Länk till vart man kan köpa billjetter',
        error: 'Länken är redan tillagd!'
      },
      variant: 'outlined',
      type: TYPES.TEXT_MULTIPLE,
      placeholder: 'http://'
    },
    IMAGE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Bild', eng: 'image' },
      helperText: {
        text: 'Url till bilden, länk till att ladda upp bild',
        error: 'Bilden är redan tillagd!'
      },
      variant: 'outlined',
      type: TYPES.TEXT_MULTIPLE,
      placeholder: 'http://'
    },
    DATE: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Datum', eng: 'date' },
      helperText: {
        text: 'Ställ in datum när eventet kommer att vara',
        error: 'datumet har redan varit'
      },
      variant: 'outlined',
      type: TYPES.DATE
    },
    PUBLISH_DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Publiceras vid', eng: 'publishedAt' },
      helperText: {
        text: 'Ställ in vilket datum och tid eventet kommer att publiceras',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE,
      variant: 'outlined'
    }
  },
  SHOW: {
    TITLE: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Titel', eng: 'title' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'standard',
      type: TYPES.TEXT
    },
    TOPIC: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Ämne', eng: 'topic' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'standard',
      type: TYPES.TEXT
    },
    THUMBNAIL: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Omslagsbild', eng: 'thumbnail' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'standard',
      type: TYPES.TEXT
    },

    DESCRIPTION: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Beskrivning', eng: 'description' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'standard',
      type: TYPES.TEXT_AREA,
      maxRows: 4
    },
    ORIGINALCONTENT: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Skapat av TH|RD', eng: 'isOriginal' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.CHECKBOX
    },
    PUBLISH_DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Publiceras vid', eng: 'publishedAt' },
      helperText: {
        text: 'Ställ in vilket datum och tid programmet kommer att publiceras',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE,
      variant: 'outlined'
    }
  },

  SHOWS: {
    ORIGINALCONTENT: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Skapat av TH|RD', eng: 'isOriginal' },
      helperText: {
        text: '',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.CHECKBOX
    },
    WATERFALL: {
      id: uuidv4(),
      required: true,
      content: { swe: 'Ska serien vara utan säsonger', eng: 'waterfall' },
      helperText: {
        text: '',
        error: ''
      },
      type: TYPES.CHECKBOX
    },
    TITLE: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Titel', eng: 'title' },
      helperText: {
        text: 'Vad är titeln på serien',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    TOPIC: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Ämne', eng: 'topic' },
      helperText: {
        text: 'Inom vilken kategori är denna serie',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },
    THUMBNAIL: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Omslagsbild', eng: 'thumbnail' },
      helperText: {
        text: 'Klistra in en bildlänk till seriens omslagsbild',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.TEXT
    },

    DESCRIPTION: {
      id: uuidv4(),
      required: true,

      content: { swe: 'Beskrivning', eng: 'description' },
      helperText: {
        text: 'Gör en beskrivning till serien som berättar vad den handlar om',
        error: ''
      },
      variant: 'outlined',
      type: TYPES.TEXT_AREA,
      maxRows: 4
    },
    PUBLISH_DATE: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Publiceras vid', eng: 'publishedAt' },
      helperText: {
        text: 'Ställ in vilket datum och tid eventet kommer att publiceras',
        error: 'datumet har redan varit'
      },
      type: TYPES.DATE,
      variant: 'outlined'
    }
  },
  TAGS: {
    TAGS: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Sökord', eng: 'tags' },
      helperText: {
        text: 'Lägg till olika ord som tillhör denna produkten',
        error: 'Sökordet har redan blivit tillagd'
      },
      type: TYPES.TEXT_MULTIPLE,
      variant: 'outlined'
    }
  },
  TAGS_STANDARD: {
    TAGS: {
      id: uuidv4(),
      required: false,
      content: { swe: 'Sökord', eng: 'tags' },
      helperText: {
        text: 'Lägg till olika ord som tillhör denna produkten',
        error: 'Sökordet har redan blivit tillagd'
      },
      type: TYPES.TEXT_MULTIPLE,
      variant: 'standard'
    }
  }
}
