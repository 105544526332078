/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  Container,
  CardMedia,
  Button,
  Card,
  MenuItem
} from '@material-ui/core'
import { TYPE_CONTENT } from '../../../components/inputTypes'
import LinearLoader from 'src/components/LinearLoader'
import { SETTINGS } from '../../../utils/pageSettings'
import useHttp from '../../../hooks/http'
import theme from '../../../theme/theme'
import DateInput from 'src/components/inputs/date'

const { REACT_APP_YOUTUBE_API_KEY } = process.env

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& > * ': {
      margin: theme.spacing(1)
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row'
    }
  },
  inputContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row'
  },
  contentCardContainer: {
    height: 'auto',
    width: 'auto',
    marginBottom: theme.spacing(1)
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > * ': {
      marginBottom: theme.spacing(1)
    }
  },
  multilineColor: {
    color: theme.palette.primary.main
  },

  selectDropdown: { color: theme.palette.primary.main },

  thumbnail: { minWidth: '300px', width: 'auto', paddingTop: '55.25%' }
}))

export default function AddEpisodeView({ seasons, parentState, postEpisode }) {
  const classes = useStyles()
  const [inputError, setInputError] = useState(false)
  const [input, setInput] = useState('')
  const [value, setValue] = parentState
  const [success, setSuccess] = useState(false)
  const [episodeInputValues, setEpisodeInputValues] = useState({
    title: '',
    description: '',
    youtubeKey: '',
    views: 0,
    thumbnail: ' ',
    duration: '',
    season: '',
    tags: []
  })
  const { isLoading, error, data, sendRequest, reqExtra, clear } = useHttp()

  const clearRef = useRef(() => {})

  clearRef.current = () => {
    clear()
  }

  useEffect(() => {
    if (!isLoading && !error && data && reqExtra === 'youtube') {
      if (!data.error) {
        try {
          let duration = data.items[0].contentDetails.duration
          let thumbnail =
            data.items[0].snippet.thumbnails.maxres?.url ||
            data.items[0].snippet.thumbnails.standard?.url ||
            data.items[0].snippet.thumbnails.high?.url ||
            data.items[0].snippet.thumbnails.meidum?.url ||
            data.items[0].snippet.thumbnails.default?.url
          setEpisodeInputValues((prevState) => ({
            ...prevState,
            duration: duration,
            thumbnail: thumbnail
          }))
          setSuccess(true)
        } catch (err) {
          setSuccess(false)
          setInputError(true)
        }
      }
    }
  }, [data, error, isLoading, reqExtra])

  useEffect(() => {
    setEpisodeInputValues((prevState) => ({
      ...prevState,
      season: value + 1
    }))
  }, [value])

  useEffect(() => {
    findYoutubeKeyRef.current()

    return () => clearRef.current()
  }, [input])

  const findYoutubeKeyRef = useRef(() => {})

  findYoutubeKeyRef.current = () => {
    let youtubeKey = extractVideoID(input)

    if (youtubeKey.length > 0) {
      const requestUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&&id=${youtubeKey}&key=${REACT_APP_YOUTUBE_API_KEY}`
      sendRequest(requestUrl, 'GET', null, 'youtube', true)
      setEpisodeInputValues((prevState) => ({
        ...prevState,
        youtubeKey: youtubeKey
      }))
      setInputError(false)
    } else {
      setInputError(true)
    }

    input === '' && setInputError(false)
  }

  function extractVideoID(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    var match = url.match(regExp)
    if (match && match[7].length === 11) {
      return match[7]
    } else {
      return ''
    }
  }

  const handleChange = (event) => {
    setInput(event.target.value)
  }

  const handleEpisodeInputChange = (event, select) => {
    const value = event.target.value
    setEpisodeInputValues((prevState) => ({
      ...prevState,
      [select]: value
    }))
  }

  const handleClick = () => {
    postEpisode(episodeInputValues)
  }

  const handleSeasonChange = (event) => {
    setValue(event.target.value)
  }
  const renderContent = (inputs) => {
    return Object.values(inputs).map((input) => {
      return TYPE_CONTENT.find(({ type }) => type === input.type).component(
        input,
        [episodeInputValues, setEpisodeInputValues],
        undefined
      )
    })
  }
  const renderAddEpisodeView = () => {
    return (
      <div className={classes.contentContainer}>
        <div className={classes.contentCardContainer}>
          <Card className={classes.contentCard}>
            <CardMedia
              className={classes.thumbnail}
              image={episodeInputValues.thumbnail}
            />
          </Card>
        </div>
        <Container className={classes.inputs} style={{ padding: 0 }}>
          <div className={classes.inputs}>
            <TextField
              key={1}
              id="standard-required"
              label="Titel"
              autoComplete="off"
              onChange={(event) => handleEpisodeInputChange(event, 'title')}
              fullWidth
              inputProps={{
                className: classes.multilineColor,
                form: {
                  autocomplete: 'off'
                }
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.main }
              }}
            />
            <TextField
              id="standard-textarea"
              rowsMax={4}
              label="Beskrivning"
              onChange={(event) =>
                handleEpisodeInputChange(event, 'description')
              }
              fullWidth
              multiline
              inputProps={{
                className: classes.multilineColor
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.main }
              }}
            />
            <TextField
              id="standard-select-currency"
              select
              label="Säsong"
              value={value}
              onChange={handleSeasonChange}
              helperText="Välj vilken säsong avsnittet tillhör"
              inputProps={{
                className: classes.multilineColor
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.main }
              }}
              SelectProps={{
                MenuProps: { classes: { paper: classes.selectDropdown } }
              }}
            >
              {new Array(seasons)
                .fill(undefined)
                .map((_, i) => i)
                .map((season) => {
                  return (
                    <MenuItem key={season} value={season}>
                      {season + 1}
                    </MenuItem>
                  )
                })}
            </TextField>
            {renderContent(SETTINGS.TAGS_STANDARD)}
            <DateInput
              theme="dark"
              parentHook={[episodeInputValues, setEpisodeInputValues]}
              label={{ swe: 'Publiseras vid', eng: 'publishedAt' }}
            />
          </div>
          <Container style={{ padding: 0 }}>
            <Button
              style={{
                color: theme.palette.primary.main,
                float: 'right'
              }}
              onClick={handleClick}
            >
              Lägg till
            </Button>
          </Container>
        </Container>
      </div>
    )
  }

  const renderAddYoutubeLinkView = () => {
    return (
      <Container style={{ padding: 0 }}>
        <TextField
          id="standard-full-width"
          placeholder="https://www.youtube..."
          helperText="Klistra in youtube url"
          fullWidth
          error={inputError}
          onChange={handleChange}
          margin="normal"
          className={classes.inputField}
          inputProps={{
            className: classes.multilineColor,
            form: {
              autocomplete: 'off'
            }
          }}
        />
      </Container>
    )
  }

  return (
    <Container style={{ padding: 0, borderRadius: '10px' }}>
      {isLoading && <LinearLoader />}
      {success ? renderAddEpisodeView() : renderAddYoutubeLinkView()}
    </Container>
  )
}
